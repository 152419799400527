import type { FC } from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

import type { Section } from "../config";
import { SideNavSection } from "./side-nav-section";
import { Logo } from "../../../components/logo";
import { Scrollbar } from "../../../components/scrollbar";
import DashboardHeader from "../../../sections/dashboard/dashboard-header";
import { NavColor } from "../../../types/settings";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { layoutSlice } from "src/features/layout/layoutSlice";
import { HelpCenter, HelpOutline } from "@mui/icons-material";
import { Typography, Button, SvgIcon } from "@mui/material";

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    switch (color) {
      case "blend-in":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "discrete":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[900],
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[50],
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.divider,
            "--nav-logo-border": theme.palette.neutral[200],
            "--nav-section-title-color": theme.palette.neutral[500],
            "--nav-item-color": theme.palette.neutral[500],
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

interface SideNavProps {
  color?: NavColor;
  sections?: Section[];
}

export const SideNav: FC<SideNavProps> = (props) => {
  const dispatch = useAppDispatch();
  const { color = "evident", sections = [] } = props;
  const cssVars = useCssVars(color);
  const SIDE_NAV_WIDTH = useAppSelector(layoutSlice.selectors.sidenavWidth);
  const maxSize = useAppSelector(layoutSlice.selectors.sidenavMaxSize);

  function handleResize(width: number) {
    dispatch(layoutSlice.actions.resizeSidenav(width));
  }

  const [otherSections, workspaceArr] = useMemo(() => {
    const workspaceSection = sections.find(section => section.id === "workspaces");
    const other = sections.filter(section => section.id !== "workspaces");
  
    // Sort the ITEMS in the workspace section by title
    const sortedWorkspaceSection = workspaceSection 
      ? {
          ...workspaceSection,
          items: [...workspaceSection.items].sort((a, b) => 
            a.title.localeCompare(b.title) // Sort by workspace title
          )
        }
      : null;
  
    return [
      other, 
      sortedWorkspaceSection ? [sortedWorkspaceSection] : [] 
    ];
  }, [sections]);

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: "var(--nav-bg)",
          borderRightColor: "var(--nav-border-color)",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          color: "var(--nav-color)",
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
        }}
      >
        <Stack sx={{ height: "100%" }}>
          <Box sx={{ p: 0.5 }}>
            <Box sx={{ display: "flex", height: 55, width: 55, ml: 1, mt: 1}}>
              <Logo />
            </Box>
          </Box>
          <Stack
            spacing={2}
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              p: 3,
              flex: 1,
            }}
          >
            <Stack component="nav" spacing={2}>
              {otherSections.map((section, index) => (
                <SideNavSection
                  items={section.items}
                  key={index}
                  action={section.action}
                  subheader={section.subheader}
                />
              ))}
            </Stack>
            <Stack
              component="nav"
              spacing={2}
              sx={{
                overflowY: "auto",
                flex: 1,
              }}
            >
              {workspaceArr.map((section, index) => (
                <SideNavSection
                  items={section.items} 
                  key={index}
                  action={section.action}
                  subheader={section.subheader}
                />
              ))}
            </Stack>
          </Stack>
          <Box
            sx={{
              p: 0.5,
              cursor: "ew-resize", 
              userSelect: "none", 
              width: "10px", 
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              zIndex: 1000,
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              const initialWidth = SIDE_NAV_WIDTH;
              const startX = e.clientX;

              const handleMouseMove = (e: MouseEvent) => {
                const dx = e.clientX - startX;
                handleResize(initialWidth + dx);
              };

              const handleMouseUp = () => {
                window.removeEventListener("mousemove", handleMouseMove);
                window.removeEventListener("mouseup", handleMouseUp);
              };

              window.addEventListener("mousemove", handleMouseMove);
              window.addEventListener("mouseup", handleMouseUp);
            }}
          />
        <Box sx={{ p: 3 }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ml:0.5}}>
            <SvgIcon fontSize="small" sx={{ color: "text.secondary" }}>
              <HelpOutline />
            </SvgIcon>
            <Typography
              component="a"
              href={"javascript:void(Tawk_API.toggle())"}
              sx={{
                typography: "body2", 
                color: "text.secondary",
                textDecoration: "none",
                cursor: "pointer",
                fontWeight: "bold"
              }}
            >
              Preciso de ajuda?
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ mr: 2, mt: 3 }} maxWidth={maxSize}>
            <DashboardHeader onMobileNav={() => {}} />
          </Stack>
        </Box>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf<NavColor>(["blend-in", "discrete", "evident"]),
  sections: PropTypes.array,
};
