import { FC, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Button,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";
import { IOnboardingQuestionsDtoTypeEnum } from "src/services/thumbz-base-api";
import { Close } from "@mui/icons-material";
import { Stack } from "@mui/system";

interface MultiSelectFieldProps {
  question: {
    field_key: string;
    required: boolean;
    options?: string[];
  };
  selectedValues: string[];
  onChange: (value: string[]) => void;
}

const MultiSelectField: FC<MultiSelectFieldProps> = ({ question, selectedValues, onChange }) => {
  const { palette } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  function _handleChange(value: unknown): void {
    const _value = Array.isArray(value) ? value : [value];
    const containUndefined = _value.some((item) => item === undefined);

    if (containUndefined) {
      return;
    }

    onChange(_value as string[]);
  }

  return (
    <Select
      multiple
      fullWidth
      required={question.required}
      name={question.field_key}
      value={selectedValues}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onChange={(event) => _handleChange(event.target.value as string[])}
      input={<OutlinedInput />}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {(selected as string[]).map((value) => (
            <Chip key={value} label={value} />
          ))}
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          style: { maxHeight: 300, width: 300 },
        },
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: palette.background.default || "white",
          zIndex: 1,
        }}
      >
        <Stack
          flex={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          pl={4}
        >
          <Typography variant="body1">Selecione 1 ou mais opções</Typography>
          <Button
            startIcon={<Close />}
            color="primary"
            variant="text"
            onClick={() => setIsOpen(false)}
          >
            Fechar
          </Button>
        </Stack>
        <Divider />
      </Box>
      {(question.options ?? []).map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={selectedValues.includes(option)} />
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelectField;
