import React from "react";
import {
  TextField,
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { IApprovalTypeField, IUpsertApprovalResponseDto } from "src/services/thumbz-base-api";

export type IHandleChange = IUpsertApprovalResponseDto;

interface FieldRendererProps {
  field: IUpsertApprovalResponseDto;
  type: IApprovalTypeField;
  // validationErrors: Array<{ apr_id: string }>;
  handleChange: (props: IHandleChange) => void;
  readOnly?: boolean;
}

export const FieldRenderer: React.FC<FieldRendererProps> = ({
  field,
  type,
  // validationErrors,
  handleChange,
  readOnly = false,
}) => {
  const { atf_label, atf_description, atf_required, atf_options, fieldType } = type;
  const apr_id = String(field.apr_id);
  const { apr_value, apv_id, atf_id, item_id } = field;

  // TODO: Fix this ↓
  const isFieldError = false;
  const label = `${atf_label} ${atf_required ? "" : ""}`;

  switch (fieldType.fld_type) {
    case "text":
      return (
        <TextField
          key={apr_id}
          label={label}
          placeholder={atf_description ?? ""}
          required={atf_required}
          fullWidth
          error={isFieldError}
          value={field.apr_value || ""}
          onChange={(e) =>
            handleChange({
              apr_id,
              apr_value: (e.target as HTMLInputElement).value || "",
              atf_id,
              apv_id,
              item_id,
            })
          }
          helperText={isFieldError ? `O campo "${atf_label}" é obrigatório.` : ""}
          disabled={readOnly}
        />
      );

    case "checkbox":
      return (
        <FormControl key={apr_id} required={atf_required} error={isFieldError} sx={{ pl: 0.5 }}>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup row>
            {(atf_options ?? []).map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={Array.isArray(apr_value) && (apr_value as string[]).includes(option)}
                    onChange={(e) => {
                      const currentValues = Array.isArray(apr_value) ? apr_value : [];
                      if (e.target.checked) {
                        handleChange({
                          apr_id,
                          apr_value: [...currentValues, option],
                          atf_id,
                          apv_id,
                          item_id,
                        });
                      } else {
                        handleChange({
                          apr_id,
                          apr_value: currentValues.filter((o) => o !== option),
                          atf_id,
                          apv_id,
                          item_id,
                        });
                      }
                    }}
                    disabled={readOnly}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
          <FormHelperText>
            {atf_required && isFieldError
              ? `O campo "${atf_label}" deve ter pelo menos uma opção selecionada.`
              : ""}
          </FormHelperText>
        </FormControl>
      );

    case "select":
      return (
        <FormControl key={apr_id} fullWidth required={atf_required} error={isFieldError}>
          <InputLabel id={`${apr_id}-label`}>{label}</InputLabel>
          <Select
            labelId={`${apr_id}-label`}
            id={apr_id}
            onChange={(e) =>
              handleChange({
                apr_id,
                apr_value: (e.target as HTMLInputElement).value,
                atf_id,
                apv_id,
                item_id,
              })
            }
            disabled={readOnly}
          >
            {atf_options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {atf_required && isFieldError && (
            <FormHelperText>O campo "{atf_label}" é obrigatório.</FormHelperText>
          )}
        </FormControl>
      );

    case "number":
      return (
        <TextField
          key={apr_id}
          label={label}
          placeholder={atf_description ?? ""}
          type="number"
          required={atf_required}
          fullWidth
          error={isFieldError}
          onChange={(e) =>
            handleChange({ apr_id, apr_value: e.target.value, atf_id, apv_id, item_id })
          }
          helperText={isFieldError ? `O campo "${atf_label}" é obrigatório.` : ""}
          disabled={readOnly}
        />
      );

    case "date":
      const today = new Date().toISOString().split("T")[0];
      return (
        <TextField
          key={apr_id}
          label={label}
          type="date"
          required={atf_required}
          fullWidth
          error={isFieldError}
          value={apr_value || ""}
          helperText={isFieldError ? `O campo "${atf_label}" é obrigatório.` : ""}
          disabled={readOnly}
          onChange={(e) =>
            handleChange({
              apr_id,
              apr_value: e.target.value,
              atf_id,
              apv_id,
              item_id,
            })
          }
          slotProps={{
            inputLabel: { shrink: true }, 
            htmlInput: { min: today },    
          }}
        />
      );

    default:
      return null;
  }
};
