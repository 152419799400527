import toast from "react-hot-toast";

const MAX_FILE_SIZE_MB = 100; 

export const validateFiles = (files: FileList | null): FileList | null => {
  if (!files) return null;

  const fileArray = Array.from(files);
  const oversizedFiles = fileArray.filter(file => file.size > MAX_FILE_SIZE_MB * 1024 * 1024);

  if (oversizedFiles.length > 0) {
    toast.error(
      `Os seguintes arquivos são muito grandes e não podem ser adicionados:\n\n${oversizedFiles
        .map((file) => file.name)
        .join("\n")}\n\nO tamanho máximo permitido é ${MAX_FILE_SIZE_MB}MB.`
    );
    return null; 
  }

  return files;
};