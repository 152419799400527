import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthChangeEvent, Session, User } from "@supabase/supabase-js";

import {
  organizationSlice,
  organizationThunks,
  setSelectedOrganization,
} from "../organization/organizationSlice";
import toast from "react-hot-toast";
import { useAppDispatch } from "../../app/hooks";
import { AUTH_TOKEN, AUTH_REFRESH_TOKEN, thumbzApi } from "../../services/thumbz-api";
import { IOrganization, IUser } from "../../services/thumbz-base-api";
import { createClient } from "src/utils/supabase/client";
import { RootState } from "src/app/store";

interface AuthState {
  user: IUser | null;
  supabaseUser: User | null;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  isDisabled: boolean;
  countdown: number;
}

interface IauthStateChanged {
  user: IUser;
}

const initialState: AuthState = {
  user: null,
  supabaseUser: null,
  loading: "idle",
  isDisabled: false,
  countdown: 0,
};

interface ILoggedInterface {
  user: IUser;
  supabaseUser: User;
  supabaseSession: Session;
  favoriteOrganization: IOrganization;
}

export const signInWithEmailAndPassword = createAsyncThunk(
  "auth/signInWithEmailAndPassword",
  async ({ email, password }: { email: string; password: string }, { dispatch }): Promise<void> => {
    const supabase = createClient();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error instanceof Error) {
      throw error;
    }
  },
);

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (): Promise<void> => {
    const supabase = createClient();

    const urlParams = new URLSearchParams(window.location.search);
    const returnTo = urlParams.get("returnTo") || "/";

    const redirectUrl = `${window.location.origin}${returnTo}`;

    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectUrl,
      },
    });

    if (error instanceof Error) {
      throw error;
    }
  },
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  try {
    const supabase = createClient();
    window.localStorage.removeItem(AUTH_TOKEN);
    window.localStorage.removeItem(AUTH_REFRESH_TOKEN);

    const { error } = await supabase.auth.signOut();
    if (error) throw error;

    const dispatch = useAppDispatch();
    dispatch(organizationSlice.actions.resetState());
    dispatch(authSlice.actions.resetState());
  } catch (error) {
    const dispatch = useAppDispatch();
    dispatch(organizationSlice.actions.resetState());
    dispatch(authSlice.actions.resetState());
  }

  return {
    supabaseUser: null,
  };
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }: { email: string }, { dispatch, getState }) => {
    const supabase = createClient();

    dispatch(setDisabled(true));
    dispatch(setCountdown(60));

    try {
      await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/conta/geral/trocar-senha`,
      });

      toast.success("Email de redefinição de senha enviado!");

      const interval = setInterval(() => {
        const currentState = getState() as RootState;
        const currentCountdown = currentState.auth.countdown;

        if (currentCountdown <= 1) {
          clearInterval(interval);
          dispatch(setDisabled(false));
          dispatch(setCountdown(0));
        } else {
          dispatch(setCountdown(currentCountdown - 1));
        }
      }, 1000);
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setDisabled(false));
      dispatch(setCountdown(0));
    }
  },
);

export const handleAuthSignIn = createAsyncThunk(
  "auth/handleAuthSignIn",
  async (loggedPayload: ILoggedInterface, { dispatch }) => {
    try {
      const { data } = await createClient().auth.getSession();
      if (!data.session) throw new Error("Usuário não autenticado");
      const { access_token, refresh_token, user } = data.session;
      window.localStorage.setItem(AUTH_TOKEN, access_token);
      window.localStorage.setItem(AUTH_REFRESH_TOKEN, refresh_token);

      await dispatch(organizationThunks.fetchUserOrganizations());
      await dispatch(setSelectedOrganization(loggedPayload.favoriteOrganization));
      await dispatch(authStateChanged({ user: loggedPayload.user }));

      return { access_token, refresh_token, supabaseUser: user };
    } catch (error) {
      console.error(error);
    }
  },
);

export const handleAuthToken = createAsyncThunk(
  "auth/handleAuthToken",
  async ({ session, event }: { event: AuthChangeEvent; session: Session | null }) => {
    if (!session?.user) throw new Error("No user session");

    const { access_token, refresh_token, user } = session;
    window.localStorage.setItem(AUTH_TOKEN, access_token);
    window.localStorage.setItem(AUTH_REFRESH_TOKEN, refresh_token);

    return { access_token, refresh_token, supabaseUser: user };
  },
);

export const _createUserWithEmailAndPassword = createAsyncThunk(
  "auth/createUserWithEmailAndPassword",
  async ({ email, password, name }: { email: string; password: string; name: string }) => {
    const supabase = createClient();
    const response = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          name,
        },
      },
    });
    await supabase.auth.signOut();
    if (response.error) {
      alert(response.error.message);
    } else {
      toast.success("Um email de confirmação foi enviado para você", {
        position: "top-center",
        duration: 20000,
      });
    }
  },
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ password }: { password: string }) => {
    const supabase = createClient();
    const { data, error } = await supabase.auth.updateUser({
      password,
    });

    if (error) {
      throw new Error("A nova senha deve ser diferente da senha atual.");
    }
  },
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authStateChanged: (state, action: PayloadAction<IauthStateChanged>) => {
      state.user = action.payload.user;
    },
    resetState: (state) => {
      state.user = initialState.user;
      state.supabaseUser = initialState.supabaseUser;
      state.isDisabled = initialState.isDisabled;
      state.countdown = initialState.countdown;
    },
    updateSelectedUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload;
    },
    setCountdown: (state, action: PayloadAction<number>) => {
      state.countdown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInWithEmailAndPassword.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(signInWithEmailAndPassword.fulfilled, (state, action) => {
      state.loading = "fulfilled";
    });
    builder.addCase(signInWithEmailAndPassword.rejected, (state, action) => {
      if (action.error.message) toast.error(action.error.message);
      state.user = null;
      state.loading = "rejected";
    });
    builder.addCase(forgotPassword.fulfilled, (_state) => {});
    builder.addCase(_createUserWithEmailAndPassword.fulfilled, (_state) => {});
    builder.addCase(handleAuthSignIn.fulfilled, (state, action) => {
      state.supabaseUser = action.payload?.supabaseUser ?? null;
      state.loading = "fulfilled";
    });
    builder.addCase(handleAuthSignIn.rejected, (state) => {
      state.user = null;
      state.supabaseUser = null;
      state.loading = "rejected";
    });
    builder.addCase(signOut.pending, (state, _action) => {
      state.loading = "pending";
    });
    builder.addCase(signOut.fulfilled, (state, _action) => {
      state.supabaseUser = null;
      state.user = null;
      state.loading = "fulfilled";
    });
    builder.addCase(signOut.rejected, (state) => {
      state.supabaseUser = null;
      state.user = null;
    });

    // changePassword
    builder.addCase(changePassword.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = "fulfilled";
      toast.success("Senha alterada com sucesso");
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      if (action.error.message) toast.error(action.error.message);
      state.loading = "rejected";
    });
  },
  selectors: {
    selectUser: (state) => {
      return state.user;
    },
    selectUserPhoto: (state) => {
      return `${state.user?.usu_photo.ast_url}?${state.user?.usu_photo.ast_updated_at}`;
    },
    selectSupabaseUser: (state) => {
      return state.supabaseUser;
    },
    shouldHydrateUser: (state) => {
      return state.user === null && state.supabaseUser !== null;
    },
    isAuthenticated: (state) => {
      return state.user !== null;
    },
    selectIsDisabled: (state) => state.isDisabled,
    selectCountdown: (state) => state.countdown,
  },
});

export const { authStateChanged, setDisabled, setCountdown } = authSlice.actions;

export const authThunks = {
  signInWithGoogle,
  signInWithEmailAndPassword,
  signOut,
  handleAuthToken,
  forgotPassword,
  handleAuthSignIn,
  _createUserWithEmailAndPassword,
  changePassword,
};
