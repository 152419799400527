import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { Add, Remove, InfoOutlined } from "@mui/icons-material";
import { useBillingStatus } from "src/hooks/use-billing";
import { formatPrice } from "../dashboard/account/account-billing-settings";
import { getTieredUnitPrice } from "src/utils/get-tired-unit-tiers-stripe-pricing";
import { useMutation } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { LoadingButton } from "@mui/lab";

export const ManageWorkspacesCard = () => {
  const { org_id } = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const { api } = useThumbzApi();
  const { data, refetchAllQueries } = useBillingStatus();
  const { used_workspaces = 0, total_workspaces = 0, stripe_details } = data;

  const currentUnitPrice = stripe_details?.amountPaid ?? 0;
  const [workspaceCount, setWorkspaceCount] = React.useState(total_workspaces);

  React.useEffect(() => {
    setWorkspaceCount(total_workspaces);
  }, [total_workspaces]);

  const currentPlan = stripe_details?.planPrices.find((p) => p.id === stripe_details.currentPlanId);
  const currentPlanTiers = currentPlan?.tiers ?? [];

  const tieredUnitPrice = getTieredUnitPrice(workspaceCount, currentPlanTiers);

  const currentTotal = total_workspaces * currentUnitPrice;
  const newTotal = workspaceCount * tieredUnitPrice;

  const discountPerUnit =
    tieredUnitPrice < currentUnitPrice ? currentUnitPrice - tieredUnitPrice : 0;

  const isSame = workspaceCount === total_workspaces;
  const isUpgrade = workspaceCount > total_workspaces;
  const isDowngrade = workspaceCount < total_workspaces;
  const isDowngradeAllowed = workspaceCount >= used_workspaces;

  const handleIncrement = () => {
    setWorkspaceCount((prev = 0) => prev + 1);
  };

  const handleDecrement = () => {
    setWorkspaceCount((prev = 0) => Math.max(1, prev - 1));
  };

  const updateQuantity = useMutation({
    mutationFn: api.billing.billingControllerUpdateSubscriptionQuantity,
    mutationKey: ["updateSubscriptionQuantity"],
    onSuccess: ({ total_workspaces }) => {
      toast.success("Quantidade de workspaces atualizada com sucesso!");
      setWorkspaceCount(total_workspaces);
      refetchAllQueries();
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Gerenciar Workspaces
        </Typography>

        <Typography variant="body2" color="text.secondary" gutterBottom>
          Ajuste a quantidade de workspaces disponíveis na sua assinatura
        </Typography>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Stack>
                <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
                  Quantidade de Workspaces
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    variant="outlined"
                    onClick={handleDecrement}
                    size="small"
                    sx={{
                      color: "text.secondary",
                      borderColor: "lightgray",
                      borderRadius: 1,
                      padding: 1,
                      minWidth: 0,
                    }}
                  >
                    <Remove sx={{ fontSize: 14 }} />
                  </Button>
                  <Typography variant="h6" sx={{ width: 30, textAlign: "center" }}>
                    {workspaceCount}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={handleIncrement}
                    size="small"
                    sx={{
                      color: "text.secondary",
                      borderColor: "lightgray",
                      borderRadius: 1,
                      padding: 1,
                      minWidth: 0,
                    }}
                  >
                    <Add sx={{ fontSize: 14 }} />
                  </Button>
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <Tooltip title="Ajuste a quantidade de workspaces para ver o novo valor da sua assinatura.">
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  </IconButton>
                </Stack>
              </Stack>

              <Stack>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 0.25 }}>
                  Detalhes:
                </Typography>
                <Typography variant="body2">
                  • Você está usando {used_workspaces} workspace
                  <br />• Você está pagando por {total_workspaces} workspaces
                  <br />• Você tem {total_workspaces - used_workspaces} workspace disponível
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>
              Resumo da Alteração
            </Typography>

            <Stack bgcolor={"#f9f9f9"} p={2} borderRadius={1} spacing={1}>
              {isSame && isDowngradeAllowed ? (
                <Typography variant="body1" color="text.secondary">
                  Ajuste a quantidade de workspaces para ver o novo valor da sua assinatura.
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" color="text.primary">
                    Plano atual: {total_workspaces} ×{" "}
                    {formatPrice(currentUnitPrice * 100, "BRL", "pt-BR")} ={" "}
                    {formatPrice(currentTotal * 100, "BRL", "pt-BR")}/mês
                  </Typography>

                  <Typography variant="body2" color="text.primary">
                    Novo valor: {workspaceCount} ×{" "}
                    {formatPrice(tieredUnitPrice * 100, "BRL", "pt-BR")} ={" "}
                    {formatPrice(newTotal * 100, "BRL", "pt-BR")}/mês
                  </Typography>

                  {isUpgrade && discountPerUnit > 0 && (
                    <Typography variant="body2" color="success.main">
                      Economia: {formatPrice(discountPerUnit * 100, "BRL", "pt-BR")} por workspace
                      com desconto por volume!
                    </Typography>
                  )}

                  <LoadingButton
                    variant="contained"
                    color="warning"
                    size="small"
                    fullWidth
                    sx={{ mt: 1 }}
                    disabled={isDowngrade && !isDowngradeAllowed}
                    loading={updateQuantity.isPending}
                    onClick={() => {
                      updateQuantity.mutate({
                        new_quantity: workspaceCount,
                        org_id: org_id,
                      });
                    }}
                  >
                    {isUpgrade ? "Aumentar Plano" : "Reduzir Plano"}
                  </LoadingButton>

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textAlign="center"
                    sx={{ mt: 0.5 }}
                  >
                    {isDowngrade && !isDowngradeAllowed
                      ? "Você precisa arquivar workspaces antes de reduzir o plano."
                      : "A alteração será aplicada imediatamente e cobrada proporcionalmente."}
                  </Typography>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
