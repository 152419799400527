import { Stack } from "@mui/system";
import { createFileRoute } from "@tanstack/react-router";
import { PageContainer } from "src/components/page-container";
import { OrganizationUsage } from "src/components/usage/organization-usage";
import { usePageView } from "src/hooks/use-page-view";
import { AccountBillingSettings } from "src/sections/dashboard/account/account-billing-settings";
import { AccountBillingStatus } from "src/sections/dashboard/account/account-billing-status";
// import { BillingPlans } from "src/sections/pricing/BillingPlans";
import { CurrentSubscriptionCard } from "src/sections/pricing/CurrentSubscriptionCard";
import { ManageWorkspacesCard } from "src/sections/pricing/ManageWorkspacesCard";

export const Route = createFileRoute("/_authenticated/organizacao/faturamento/")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();

  return (
    <PageContainer
      title="Faturamento"
      subtitle="Gerencie suas informações de faturamento e visualize suas faturas."
      // leftNode={<AccountBillingStatus force_show />}
    >
      <Stack spacing={4}>
        <CurrentSubscriptionCard />
        <ManageWorkspacesCard />
        {/* <BillingPlans /> */}
        <AccountBillingSettings />
        {/* <OrganizationUsage type="workspaces" /> */}
      </Stack>
    </PageContainer>
  );
};
