import { createFileRoute, useLoaderData, useNavigate } from "@tanstack/react-router";

import { usePageView } from "src/hooks/use-page-view";
import { ApprovalContent } from "src/components/approval/ApprovalContent";
import { GridPageContainer, PageContainer } from "src/components/page-container";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Grid,
  MobileStepper,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useSelector } from "react-redux";
import {
  approvalCreateSlice,
  goToStep1,
  goToStep2,
  goToStep3,
  goToStep4,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { thumbzApi, useThumbzApi } from "src/services/thumbz-api";
import { IApproval, IStatusTypeStpTypeEnum } from "src/services/thumbz-base-api";
import { useEffect } from "react";
import { SplashScreen } from "src/components/splash-screen";
import { Stack } from "@mui/system";

const queryClient = new QueryClient();

export const Route = createFileRoute("/_authenticated/workspaces/$wrk_id/aprovacao/$apv_id/")({
  component: () => <Page />,
  pendingComponent: () => <SplashScreen />,
  loader: async ({ params, navigate }) => {
    const { wrk_id, apv_id } = params;

    const approvalQueryKey = queryKeys.approval.approvalControllerGet(apv_id).queryKey;
    const approval: IApproval | undefined = queryClient.getQueryData<IApproval>(approvalQueryKey);
    let status: IStatusTypeStpTypeEnum = IStatusTypeStpTypeEnum.NOT_STARTED;

    if (!approval) {
      const res = await queryClient.fetchQuery({
        queryKey: queryKeys.approval.approvalControllerGetStatus(apv_id).queryKey,
        queryFn: () => thumbzApi.approval.approvalControllerGetStatus(apv_id),
      });

      status = res.stp_type;
    }

    if (status !== IStatusTypeStpTypeEnum.NOT_STARTED) {
      navigate({
        from: "/workspaces/$wrk_id/aprovacao/$apv_id",
        to: "/workspaces/$wrk_id/aprovacao/$apv_id/detalhes",
        params: { wrk_id, apv_id },
        replace: true,
      });
    }
  },
});

const Page = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { api } = useThumbzApi();
  const { apv_id, wrk_id } = Route.useParams();
  const dispatch = useAppDispatch();
  const approvalState = useAppSelector((state) => state.approvalCreate);
  const isFetching = useAppSelector(approvalCreateSlice.selectors.hasAnyStageLoading);
  const allSteps = useSelector(approvalCreateSlice.selectors.selectAllSteps);
  const activeStep = useSelector(approvalCreateSlice.selectors.selectActiveStep);
  const isBackEnabled = useSelector(approvalCreateSlice.selectors.selectIsBackActiveEnabled);
  const isNextEnabled = useSelector(approvalCreateSlice.selectors.selectIsNextActiveEnabled);

  usePageView();

  const approvalQuery = useQuery({
    queryKey: queryKeys.approval.approvalControllerGet(apv_id).queryKey,
    queryFn: () => api.approval.approvalControllerGet(apv_id),
    enabled: !!apv_id,
  });
  const approval = approvalQuery.data;

  useEffect(() => {
    if (approvalQuery.data) {
      dispatch(approvalCreateSlice.actions.setApproval(approvalQuery.data));

      approvalQuery.data.stages.forEach((stage) => {
        queryClient.prefetchQuery({
          queryFn: () => api.approvalStage.approvalStageControllerFind(stage.stg_id),
          queryKey: queryKeys.approvalStage.approvalStageControllerFind(stage.stg_id).queryKey,
        });
      });
    }
  }, [api.approvalStage, approvalQuery.data, dispatch, queryClient]);

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: queryKeys.customer.customerControllerList({ wrk_id }).queryKey,
      queryFn: () => api.customer.customerControllerList({ wrk_id }),
    });
    queryClient.prefetchQuery({
      queryKey: queryKeys.workspace.get({ wrk_id: wrk_id }).queryKey,
      queryFn: () => api.workspace.workspaceControllerGet(wrk_id),
    });
  }, [api.customer, api.workspace, queryClient, wrk_id]);

  const handleNext = () => {
    if (activeStep === 0) dispatch(goToStep1());
    if (activeStep === 1) dispatch(goToStep2());
    if (activeStep === 2) dispatch(goToStep3());
    if (activeStep === 3) dispatch(goToStep4());
  };

  const handleBack = () => {
    dispatch(approvalCreateSlice.actions.backStep());
  };

  if (approval?.apv_status?.statusType?.stp_type !== IStatusTypeStpTypeEnum.NOT_STARTED) {
    if (approval) {
      navigate({
        from: "/workspaces/$wrk_id/aprovacao/$apv_id",
        to: "/workspaces/$wrk_id/aprovacao/$apv_id/detalhes",
        params: { wrk_id, apv_id },
        replace: true,
      });
    }
  }

  useEffect(() => {
    return () => {
      dispatch(approvalCreateSlice.actions.reset());
    };
  }, [dispatch]);

  if (approvalQuery.isLoading) {
    return <SplashScreen />;
  }

  return (
    <GridPageContainer
      title={approval?.apv_name ?? "Aprovação"}
      subtitle="Gerencie e acompanhe o progresso desta aprovação"
      layoutType="fixed"
      breadcrumbs={[
        {
          href: `/workspaces/${wrk_id}`,
          label: "Workspaces",
        },
        {
          href: `/workspaces/${wrk_id}/aprovacao/${apv_id}`,
          label: "Aprovação",
        },
      ]}
      leftChildren={
        <>
          <Box px={2} mb={2}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {allSteps.map((step, index) => (
                <Step key={step.value}>
                  <StepLabel>{step.label}</StepLabel>
                  <Typography sx={{ mt: 1, mb: 1 }}>{step.description}</Typography>
                </Step>
              ))}
            </Stepper>
          </Box>

          <MobileStepper
            variant="progress"
            steps={allSteps.length}
            position="static"
            activeStep={activeStep}
            sx={{ flexGrow: 1 }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={!isNextEnabled || isFetching || !approval || approvalQuery.isLoading}
              >
                Próximo
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={!isBackEnabled || approvalQuery.isLoading || isFetching}
              >
                <KeyboardArrowLeft />
                Voltar
              </Button>
            }
          />
        </>
      }
      rightChildren={
        <ApprovalContent
          wrk_id={wrk_id}
          apv_id={apv_id}
          activeStep={activeStep}
          onNext={handleNext}
          onBack={handleBack}
          approval={approval!}
        />
      }
    />
  );
};

export default Page;
