import type { FC, FormEvent } from "react";
import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { thumbzApi } from "src/services/thumbz-api";
import { IOnboardingQuestionsDtoTypeEnum } from "src/services/thumbz-base-api";
import { useOrganization } from "src/contexts/organization/OrganizationContext";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import PhoneNumberInput from "src/components/form/PhoneNumberInput";
import { MuiTelInput } from "mui-tel-input";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  Chip,
  MenuList,
  Checkbox,
  ListItemText,
  Divider,
  FormHelperText,
  Button,
  ListSubheader,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import MultiSelectField from "./MultiSelectField";

export const ContactForm: FC = () => {
  const [answer, setAnswer] = useState<Record<string, string | string[]>>({});
  const dispatch = useAppDispatch();
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const phoneUtil = PhoneNumberUtil.getInstance();
  const questionsQuery = useQuery({
    queryKey: ["contact-form"],
    queryFn: thumbzApi.organization.organizationControllerOnboardingQuestions,
  });

  const questionsMutation = useMutation({
    mutationFn: thumbzApi.organization.organizationControllerFillOnboardingQuestions,
    onSuccess: (response) => {
      setAnswer({});
      dispatch(organizationSlice.actions.finishOnboarding(response));
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  function handleChange(key: string, value: string[] | string): void {
    setAnswer((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function validatePhoneNumber(value: string, countryCode: string): boolean {
    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(value, countryCode);
      return phoneUtil.isValidNumber(parsedNumber);
    } catch {
      return false;
    }
  }

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault();

      const notArray = [
        IOnboardingQuestionsDtoTypeEnum.Text,
        IOnboardingQuestionsDtoTypeEnum.Number,
        IOnboardingQuestionsDtoTypeEnum.Textarea,
        IOnboardingQuestionsDtoTypeEnum.Phone,
      ];
      const allQuestions = questionsQuery.data ?? [];
      const sanitizedResponse = Object.entries(answer).reduce((acc, [key, value]) => {
        const isNotArray = allQuestions.some(
          ({ field_key, type, id }) => field_key === key && notArray.includes(type),
        );

        if (isNotArray) {
          return {
            ...acc,
            [key]: Array.isArray(value) ? value[0] : value,
          };
        }

        return {
          ...acc,
          [key]: value,
        };
      }, {});

      questionsMutation.mutate({
        org_id: String(selectedOrganization?.org_id),
        questions: sanitizedResponse,
      });
    },
    [answer, questionsMutation, questionsQuery.data, selectedOrganization?.org_id],
  );

  return (
    // prevent url redirect
    <form onSubmit={handleSubmit}>
      <>
        <Grid container spacing={3}>
          {questionsQuery.data?.map((question) => {
            return (
              <Grid xs={12} sm={12} key={question.id} mb={2}>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      color: "text.primary",
                      mb: 1,
                    }}
                  >
                    {question.question_title} {question.required && "*"}
                  </FormLabel>
                  {(() => {
                    switch (question.type) {
                      case IOnboardingQuestionsDtoTypeEnum.Select:
                        return (
                          <MultiSelectField
                            question={question}
                            selectedValues={
                              Array.isArray(answer[question.field_key])
                                ? (answer[question.field_key] as string[])
                                : []
                            }
                            onChange={(value: string | string[]) =>
                              handleChange(question.field_key, value)
                            }
                          />
                        );
                      case IOnboardingQuestionsDtoTypeEnum.Number:
                        return (
                          <OutlinedInput
                            name={question.field_key}
                            disabled={questionsMutation.isPending}
                            required={question.required}
                            onChange={(event) => {
                              handleChange(question.field_key, event.target.value);
                            }}
                            placeholder={question.question_description || undefined}
                            inputProps={{ min: 0 }}
                            type="number"
                          />
                        );
                      case IOnboardingQuestionsDtoTypeEnum.Phone:
                        return (
                          <MuiTelInput
                            label={"Com DDD e telefone"}
                            value={
                              Array.isArray(answer[question.field_key])
                                ? answer[question.field_key][0]
                                : ""
                            }
                            onChange={(value, info) => {
                              handleChange(question.field_key, value);

                              setAnswer((prev) => ({
                                ...prev,
                                [question.field_key]: [value],
                              }));
                            }}
                            error={
                              question.required &&
                              !validatePhoneNumber(
                                Array.isArray(answer[question.field_key])
                                  ? answer[question.field_key][0]
                                  : "",
                                "BR", // Replace with dynamic country code if needed
                              )
                            }
                            helperText={
                              question.required &&
                              (!Array.isArray(answer[question.field_key]) ||
                                !answer[question.field_key][0])
                                ? "Campo obrigatório"
                                : question.required &&
                                    !validatePhoneNumber(
                                      Array.isArray(answer[question.field_key])
                                        ? answer[question.field_key][0]
                                        : "",
                                      "BR", // Replace with dynamic country code if needed
                                    )
                                  ? "Telefone inválido"
                                  : undefined
                            }
                            defaultCountry="BR"
                            fullWidth
                            required={question.required}
                            disabled={questionsMutation.isPending}
                          />
                        );
                      default:
                        return (
                          <>
                            <OutlinedInput
                              name={question.field_key}
                              required={question.required}
                              disabled={questionsMutation.isPending}
                              onChange={(e) => handleChange(question.field_key, e.target.value)}
                              type="text"
                            />

                            {question.question_description && (
                              <FormHelperText>{question.question_description}</FormHelperText>
                            )}
                          </>
                        );
                    }
                  })()}
                  {/* has error */}
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            loading={questionsMutation.isPending}
          >
            Continuar
          </LoadingButton>
        </Box>
      </>
    </form>
  );
};
