import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Stack,
  useTheme,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { IApprovalFlowStage } from "src/services/thumbz-base-api";
import { Reorder } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { queryKeys } from "src/config/query-key";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";

interface ApprovalFlowReorderFormProps {
  open: boolean;
  stages: IApprovalFlowStage[];
  flow_id: string;
  onClose: () => void;
  onSuccess: (stages: IApprovalFlowStage[]) => void;
}

const reorder = (
  list: IApprovalFlowStage[],
  startIndex: number,
  endIndex: number,
): IApprovalFlowStage[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => ({
    ...item,
    afs_order: index + 1,
  }));
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 1 * 2,
  margin: `0 0 ${4}px 0`,

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const ApprovalFlowReorderForm: React.FC<ApprovalFlowReorderFormProps> = ({
  open,
  stages,
  flow_id,
  onClose,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();
  const { palette } = useTheme();
  const [localStages, setLocalStages] = useState<IApprovalFlowStage[]>([]);

  useEffect(() => {
    const sortedStages = [...stages].sort((a, b) => a.afs_order - b.afs_order);
    setLocalStages(sortedStages);
  }, [stages]);

  const upsertMutation = useMutation({
    mutationFn: api.approvalFlowStage.approvalFlowStageControllerUpsert,
  });

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedStages = reorder(localStages, result.source.index, result.destination.index);

    setLocalStages(reorderedStages);
  };

  const handleSave = () => {
    const promiseArray = localStages.map((stage) => upsertMutation.mutateAsync(stage));

    Promise.all(promiseArray).then(() => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      });
      if (onSuccess) {
        onSuccess(localStages);
      }
      toast.success("Etapas reordenadas com sucesso");
      onClose();
    });
    // .catch((error) => {
    //   getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
    // });
  };

  const highlightColor = palette.primary.main;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reordenar Etapas</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                {localStages.map((item, index) => (
                  <Draggable key={item.afs_id} draggableId={item.afs_id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <Card
                          variant="outlined"
                          style={{
                            borderRadius: 0,
                            backgroundColor: snapshot.isDragging ? highlightColor : "inherit",
                          }}
                        >
                          <CardContent style={{ cursor: "grab", padding: 6 }}>
                            <Stack direction="row" spacing={2}>
                              <Reorder />
                              {item.afs_name}
                            </Stack>
                          </CardContent>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
