import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { organizationSlice } from "../features/organization/organizationSlice";
import OnboardingForm from "../sections/onboarding/OnboardingForm";

interface OnboardingGuardProps {
  children: ReactNode;
}

export const OnboardingGuard: FC<OnboardingGuardProps> = (props) => {
  const { children } = props;

  const hasCompletedOnboarding = useSelector(organizationSlice.selectors.hasCompletedOnboarding);

  if (!hasCompletedOnboarding) {
    return <OnboardingForm />;
  }

  return <>{children}</>;
};

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};
