import React from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  approvalEditSlice,
  approvalEditThunks,
} from "src/features/approval-edit/approvalEditSlice";
import CloseIcon from "@mui/icons-material/Close";
import { ChatBody } from "./chat/chat-body";
import { ApprovalEditUpload } from "./approval-edit-upload";
import { Stack } from "@mui/system";
import { useConfirm } from "material-ui-confirm";
import { ChatCustomerSelector } from "./chat/chat-customer-selector";
import { GenericHint } from "src/components/hint/generic-hint";
import { Lightbulb } from "@mui/icons-material";
import { ChatMessage } from "./chat/chat-message";
import toast from "react-hot-toast";
import {
  ISliceItemAssetUpsertDto,
  ISliceItemUpsertDto,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { Mimetype } from "src/utils/get-mimetype";
import { RichTextEditor } from "src/components/form/RichTextEditor";
import { ApprovalTypeFields } from "src/components/approval/approval-type-fields";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import { IHandleChange } from "src/components/approval/field-renderer";

interface IApprovalEditDrawerProps {}

export const ApprovalEditDrawer: React.FC<IApprovalEditDrawerProps> = () => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();
  const approval = useAppSelector(approvalEditSlice.selectors.approval);
  const selected = useAppSelector(approvalEditSlice.selectors.selectedApprovalItem);
  const comments = useAppSelector(approvalEditSlice.selectors.allComments);
  const isDraft = useAppSelector(approvalEditSlice.selectors.isDraft);
  const isOpen = !!selected;

  function closeDrawer() {
    dispatch(approvalEditSlice.actions.unselectApprovalItem());
  }

  async function handleClose() {
    if (isDraft) {
      confirm({
        description: "Existem alterações não salvas. Deseja realmente sair?",
        confirmationText: "Sim",
        cancellationText: "Cancelar",
      }).then(closeDrawer);
    } else {
      closeDrawer();
    }
  }

  function handleEditDescription(description: string): void {
    if (selected?.item_id) {
      dispatch(
        approvalEditSlice.actions.changeDescription({
          item_id: selected.item_id,
          description,
        }),
      );
    }
  }

  function handleEditResponse(props: IHandleChange): void {
    if (selected?.item_id) {
      dispatch(
        approvalEditSlice.actions.updateResponses({
          item_id: selected.item_id,
          ...props,
        }),
      );
    }
  }

  async function handleSave() {
    if (!selected || !approval?.apv_id) {
      toast.error("Erro ao salvar item.");
      return;
    }

    const _assets: ISliceItemAssetUpsertDto[] = [
      ...selected.approvalItemAsset.map((asset) => ({
        aia_id: asset.aia_id,
        aia_order: asset.aia_order,
        file: null,
        already_uploaded_url: asset.asset.ast_url,
        ast_id: asset.asset.ast_id,
        props: {
          mimetype: asset.asset.ast_mimetype as Mimetype,
        },
      })),
    ];

    const arg: ISliceItemUpsertDto = {
      apv_id: approval?.apv_id,
      assets: _assets,
      isSynced: false,
      responses:
        selected.responses?.map((response) => ({
          ...response,
          apv_id: approval.apv_id,
          item_id: selected.item_id,
          atf_id: response.approvalTypeField.atf_id,
        })) || [],
      item_id: selected.item_id,
      item_order: selected.item_order,
      item_description: selected.item_description ?? "",
    };

    dispatch(approvalEditThunks.upsertApprovalItem(arg));
  }

  const hasApprovalTypeFields = selected?.responses && selected.responses.length > 0;

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: { xs: "100%", sm: "80%", md: "70%", lg: "70%", xl: "60%" },
        },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Editar item</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {selected ? (
          <Grid container spacing={4} mt={1}>
            {/* Divisão 8 colunas para edição do ApprovalItem */}
            <Grid item xs={12} md={6} lg={6}>
              <Stack spacing={4}>
                <RichTextEditor
                  value={selected.item_description ?? ""}
                  onChange={handleEditDescription}
                />

                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6">Complementares</Typography>
                </Stack>
                {hasApprovalTypeFields && approval ? (
                  <ApprovalTypeFields
                    approvalTypeFields={approval?.approvalType?.approvalTypeFields || []}
                    onFieldChange={handleEditResponse}
                    responses={
                      selected?.responses?.map((r) => ({
                        apr_value: r.apr_value,
                        apv_id: approval.apv_id,
                        atf_id: r.approvalTypeField.atf_id,
                        apr_id: r.apr_id,
                        item_id: undefined,
                      })) || []
                    }
                  />
                ) : (
                  <EmptyPlaceholder
                    overlineText="Nada por aqui"
                    showElevation={false}
                    customSubtitle="Esse item não possui campos complementares."
                    showButton={false}
                  />
                )}

                <ApprovalEditUpload />
                {approval && (
                  <Button fullWidth variant="contained" onClick={handleSave}>
                    Salvar
                  </Button>
                )}
              </Stack>
            </Grid>

            {/* Divisão 4 colunas para o chat */}
            <Grid item xs={12} md={6} lg={6}>
              <Stack>
                <Typography variant="h6">Chat</Typography>
                {/* Adicione aqui o componente de chat */}
                <GenericHint text={"Selecione um aprovador para visualizar as observações."} />

                <Stack spacing={2} mt={2}>
                  <ChatCustomerSelector />
                  <Divider />
                  <ChatBody />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1" mt={2}>
            Nenhum item selecionado.
          </Typography>
        )}
      </Box>
    </Drawer>
  );
};
