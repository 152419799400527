interface Asset {
  file?: File;
  aia_order?: number;
  aia_id?: string;
  props?: {
    mimetype: string;
  };
}

interface FormDataPayload {
  apv_id: string;
  item_description?: string;
  item_id?: string;
  item_order: number;
  assets?: Asset[];
}

export const createFormData = (data: FormDataPayload): FormData => {
  try {
    const formData = new FormData();
    formData.append("apv_id", data.apv_id);

    if (data.item_description) formData.append("item_description", data.item_description);
    if (data.item_id) formData.append("item_id", data.item_id);
    if (data.item_order != undefined) {
      formData.append("item_order", String(data.item_order));
    }

    data.assets?.forEach(({ file, aia_order, aia_id }, index) => {
      if (aia_id) {
        formData.append(`assets[${index}].aia_id`, aia_id);
      }

      formData.append(`assets[${index}].aia_order`, String(aia_order ?? 999));

      if (file) formData.append(`assets[${index}].file`, file);
    });

    return formData;
  } catch (error) {
    console.error("Error creating form data", error);
    throw new Error("Error creating form data");
  }
};
