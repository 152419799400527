import { CircularProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useFetchDashboardResume } from "src/hooks/use-fetch-dashboard";

interface WorkspaceDashboardProps {
  wrk_id: string | undefined;
  startDate?: string;
  endDate?: string;
}

export const WorkspaceDashboard: React.FC<WorkspaceDashboardProps> = ({ wrk_id }) => {
  const { data, isLoading, error } = useFetchDashboardResume({ wrk_id: wrk_id || null });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 64px)",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading && <CircularProgress />}

      {error && (
        <Stack spacing={2} alignItems="center">
          <Typography variant="body1" color="error">
            Erro ao carregar dashboard. Tente novamente mais tarde.
          </Typography>
        </Stack>
      )}

      {data?.iframe_url && !isLoading && !error && (
        <iframe
          key={data?.iframe_url}
          title="Dashboard de Infrações"
          src={data.iframe_url}
          width="100%"
          height="100%"
          frameBorder="0"
        />
      )}
    </Box>
  );
};
