import type { FC, ReactNode } from "react";

import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { StripePricingTable } from "src/components/stripe/strip-pricing-table";
import { Box, Container, Stack } from "@mui/system";
import { useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { TopNav } from "src/layouts/dashboard/marketing/top-nav";
import { useBillingStatus } from "src/hooks/use-billing";
import { ManageWorkspacesCard } from "src/sections/pricing/ManageWorkspacesCard";
import { CurrentSubscriptionCard } from "src/sections/pricing/CurrentSubscriptionCard";
import { Alert } from "@mui/material";

interface BillingGuardProps {
  children: ReactNode;
}

export const BillingGuard: FC<BillingGuardProps> = (props) => {
  const { data } = useBillingStatus();
  const { available_workspaces } = data;
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const shouldShowBilling = useAppSelector(organizationSlice.selectors.shouldShowBilling);
  const { children } = props;

  if (shouldShowBilling) {
    return (
      <Box>
        <Box mb={"100px"}>
          <TopNav />
        </Box>
        <Container>
          <EmptyPlaceholder
            overlineText={`A organização "${selectedOrganization?.org_name}" não possui um plano ativo`}
            customSubtitle={"Você pode testar gratuitamente por 15 dias"}
            showButton={false}
            showElevation={true}
          >
            <StripePricingTable />
          </EmptyPlaceholder>
        </Container>
      </Box>
    );
  }

  if (available_workspaces && available_workspaces <= 0) {
    return (
      <Box>
        <Box mb={"100px"}>
          <TopNav />
        </Box>
        <Container>
          <Alert severity="error">
            A organização "{selectedOrganization?.org_name}" não possui mais workspaces disponíveis.
            Você precisa comprar mais workspaces ou excluir alguns workspaces para continuar usando
            o serviço.
          </Alert>
          <Stack spacing={1} mt={1}>
            <CurrentSubscriptionCard />
            <ManageWorkspacesCard />
          </Stack>
        </Container>
      </Box>
    );
  }

  return <>{children}</>;
};
