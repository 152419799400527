import { useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "src/app/hooks";
import { queryKeys } from "src/config/query-key";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { useThumbzApi } from "src/services/thumbz-api";
import {
  IDashboardMetabaseIframe,
  IDashboardRequestDto,
  IDashboardWorkspaceRequestDto,
  IMetabaseTheme,
} from "src/services/thumbz-base-api";

interface IUseFetchDashboardResume {
  data: IDashboardMetabaseIframe | undefined;
  isLoading: boolean;
  error: Error | null;
}

export interface IUseFetchDashboardResumeParams {
  wrk_id: string | null;
}

export function useFetchDashboardResume({
  wrk_id,
}: IUseFetchDashboardResumeParams): IUseFetchDashboardResume {
  const { palette } = useTheme();
  const { org_id } = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const { api } = useThumbzApi();

  const paramsOrg: IDashboardRequestDto = {
    org_id,
    theme: palette.mode === "dark" ? IMetabaseTheme.Dark : IMetabaseTheme.Light,
  };

  const paramsWrk: IDashboardWorkspaceRequestDto = {
    org_id,
    theme: palette.mode === "dark" ? IMetabaseTheme.Dark : IMetabaseTheme.Light,
    wrk_id: wrk_id || undefined,
  };

  const isOrganization = wrk_id === null || wrk_id === undefined;

  // organization dashboard
  const {
    data: organizationData,
    isLoading: isLoadingOrganization,
    error: organizationError,
  } = useQuery({
    enabled: isOrganization,
    queryKey: queryKeys.dashboard.organization(paramsOrg).queryKey,
    queryFn: () => api.dashboard.dashboard(paramsOrg),
    staleTime: 1000 * 60 * 60 * 23,
    refetchInterval: 1000 * 60 * 60 * 23,
    refetchIntervalInBackground: true,
  });

  // workspace dashboard
  const {
    data: workspaceData,
    isLoading: isLoadingWorkspace,
    error: workspaceError,
  } = useQuery({
    enabled: !isOrganization,
    queryKey: queryKeys.dashboard.workspace(paramsWrk).queryKey,
    queryFn: () =>
      api.dashboard.dashboardByWorkspace({
        ...paramsWrk,
        bordered: false,
      }),
    staleTime: 1000 * 60 * 60 * 23,
    refetchInterval: 1000 * 60 * 60 * 23,
    refetchIntervalInBackground: true,
  });

  return {
    data: isOrganization ? organizationData : workspaceData,
    isLoading: isLoadingOrganization || isLoadingWorkspace,
    error: organizationError || workspaceError,
  };
}
