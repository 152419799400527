import {
  Typography,
  Breadcrumbs,
  IconButton,
  Stack,
  SxProps,
  Link,
  Box,
  Grid,
} from "@mui/material";
import React from "react";
import { BreadcrumbsSeparator } from "./breadcrumbs-separator";
import { Seo, SeoProps } from "./seo";
import { RouterLink } from "src/components/router-link";
import { useRouter } from "src/hooks/use-router";
import { ArrowBack } from "@mui/icons-material";
import { RouterPaths } from "src/main";

export type ILayoutType = "fixed" | "scrollable";

export interface IPageContainerProps {
  children: React.ReactNode;
  seo?: SeoProps;
  title?: string;
  subtitle?: string;
  breadcrumbs?: { href: RouterPaths | string; label: string }[];
  sx?: SxProps;
  leftNode?: React.ReactNode;
  isLoading?: boolean;
  loadingComponent?: React.ReactNode;
  layoutType?: ILayoutType;
}

const DEFAULT_SX: SxProps = {
  flexGrow: 1,
  px: 4,
  pt: 2,
  pb: 2,
};

const PageBreadcrumbs: React.FC<{
  breadcrumbs?: { href: RouterPaths | string; label: string }[];
}> = ({ breadcrumbs }) => {
  if (!breadcrumbs || breadcrumbs.length === 0) return null;

  return (
    <Breadcrumbs separator={<BreadcrumbsSeparator />}>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return isLast ? (
          <Typography key={index} color="text.secondary" variant="subtitle2">
            {breadcrumb.label}
          </Typography>
        ) : (
          <Link
            key={index}
            color="text.primary"
            component={RouterLink}
            href={breadcrumb.href}
            variant="subtitle2"
          >
            {breadcrumb.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

const PageHeader: React.FC<{
  title?: string;
  subtitle?: string;
  breadcrumbs?: { href: RouterPaths | string; label: string }[];
  leftNode?: React.ReactNode;
}> = ({ title, subtitle, breadcrumbs, leftNode }) => {
  const router = useRouter();

  const handleBack = () => {
    if (breadcrumbs && breadcrumbs.length > 1) {
      router.history.back();
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={4} mb={4}>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {breadcrumbs && breadcrumbs.length > 1 && (
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
          )}
          {title && <Typography variant="h5">{title}</Typography>}
        </Stack>
        {subtitle && (
          <Typography variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>
        )}
        <PageBreadcrumbs breadcrumbs={breadcrumbs} />
      </Stack>
      {leftNode && (
        <Stack alignItems="center" direction="row" spacing={3}>
          {leftNode}
        </Stack>
      )}
    </Stack>
  );
};

export const PageContainer: React.FC<IPageContainerProps> = ({
  breadcrumbs,
  children,
  seo,
  subtitle,
  title,
  sx = DEFAULT_SX,
  leftNode,
  isLoading = false,
  loadingComponent = <Typography>Loading...</Typography>,
  layoutType = "scrollable",
}) => {
  const isScrollable = layoutType === "scrollable";

  return (
    <>
      <Seo title={title} {...seo} />
      <Stack
        component="main"
        sx={{
          ...DEFAULT_SX,
          ...sx,
          maxHeight: isScrollable ? "auto" : "calc(100vh - 64px)",
          overflow: isScrollable ? "visible" : "hidden",
          flexGrow: 1,
        }}
      >
        <PageHeader
          title={title}
          subtitle={subtitle}
          breadcrumbs={breadcrumbs}
          leftNode={leftNode}
        />
        <Stack flex={1} flexGrow={1}>
          {isLoading ? loadingComponent : children}
        </Stack>
      </Stack>
    </>
  );
};

interface IGridPageContainerProps extends Omit<IPageContainerProps, "children"> {
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
}

export const GridPageContainer: React.FC<IGridPageContainerProps> = ({
  breadcrumbs,
  seo,
  subtitle,
  title,
  sx = DEFAULT_SX,
  leftNode,
  isLoading = false,
  loadingComponent = <Typography>Loading...</Typography>,
  layoutType = "scrollable",
  leftChildren,
  rightChildren,
}) => {
  const isScrollable = layoutType === "scrollable";

  return (
    <>
      <Seo title={title} {...seo} />
      <Grid
        container
        spacing={2}
        flexGrow={1}
        flex={1}
        sx={{
          ...DEFAULT_SX,
          ...sx,
          maxHeight: isScrollable ? "auto" : "calc(100vh - 64px)",
          overflow: isScrollable ? "visible" : "hidden",
          flexGrow: 1,
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            overflow: isScrollable ? "auto" : "hidden",
          }}
        >
          <PageHeader
            title={title}
            subtitle={subtitle}
            breadcrumbs={breadcrumbs}
            leftNode={leftNode}
          />
          <Box flex={1} overflow="auto">
            {leftChildren}
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            overflow: isScrollable ? "auto" : "hidden",
          }}
        >
          {isLoading ? loadingComponent : rightChildren}
        </Grid>
      </Grid>
    </>
  );
};
