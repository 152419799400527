import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Button, Skeleton } from "@mui/material";
import React, { useMemo } from "react";
import { useAccountUsage } from "src/hooks/use-account-usage";
import {
  IOrganizationUsageResponse,
  IUsage,
  IUsageSeverityEnum,
} from "src/services/thumbz-base-api";
import { RouterLink } from "src/components/router-link";
import { Link } from "src/utils/router-link";
import { useBillingStatus } from "src/hooks/use-billing";

type AvailableUsageTypes = keyof IOrganizationUsageResponse;

interface AccountUsageProps {
  type: AvailableUsageTypes;
  show_when?: {
    [key in "warning" | "error" | "success" | "info"]: boolean;
  };
}

export const AccountUsage: React.FC<AccountUsageProps> = ({ type, show_when }) => {
  const { data, query } = useAccountUsage();
  const billingStatus = useBillingStatus();
  const {
    available_workspaces,
    total_workspaces,
    used_workspaces,
    should_show_warning,
    plan,
    color,
  } = billingStatus.data;
  const { error = true, info = true, success = true, warning = true } = show_when || {};

  const usageType = useMemo(() => {
    let title = "Cota de uso";
    let usage: IUsage | undefined = undefined;
    let consequence =
      "Seus serviços podem ser interrompidos se você exceder os limites do seu plano";

    switch (type) {
      case "workspaces":
        usage = data?.workspaces;
        title = "Pague somente pelo que precisar!";
        break;
      default:
        break;
    }

    const predescription = `Você já criou ${total_workspaces} workspaces, que é o limite do seu plano atual. Mas não se preocupe — é só fazer o upgrade do seu plano para continuar criando novos workspaces. É rápido e fácil!`;
    let description = `Você criou ${usage?.used} ${String(
      type,
    )} de um total de ${usage?.limit} (${usage?.amount_percentage_string})`;

    if (usage?.severity === "error") {
      if (type === "workspaces") {
        consequence = "";
      }
    }

    if (usage?.severity === "warning") {
      if (type === "workspaces") {
        consequence = "Você está prestes a atingir o limite de workspaces";
      }
    }

    if (type === "workspaces") {
      description = ``;
    }

    return {
      ...usage,
      show_warning: usage?.show_warning || false,
      amount_percentage_color: usage?.amount_percentage_color || "info",
      amount_percentage_string: usage?.amount_percentage_string || "",
      amount_percentage_value: usage?.amount_percentage_value || 0,
      limit: usage?.limit || 0,
      remaining: usage?.remaining || 0,
      severity: usage?.severity,
      used: usage?.used || 0,
      title,
      description,
      consequence,
      predescription,
    };
  }, [data, type]);

  if (query.isLoading || usageType === undefined || usageType === null) {
    return <Skeleton variant="rectangular" height={100} />;
  }

  if (usageType.severity === "error" && !error) return null;
  if (usageType.severity === "info" && !info) return null;
  if (usageType.severity === "success" && !success) return null;
  if (usageType.severity === "warning" && !warning) return null;

  if (query.isError) {
    return (
      <Alert
        severity={"error"}
        action={
          <LoadingButton
            loading={query.isFetching}
            loadingPosition="center"
            onClick={() => {
              query.refetch();
            }}
            color="inherit"
            size="small"
          >
            <Refresh />
          </LoadingButton>
        }
      >
        <AlertTitle>Erro</AlertTitle>
        {query.error?.message || "Ocorreu um erro ao buscar o uso da conta"}
      </Alert>
    );
  }

  const warningOrDanger =
    usageType.severity === IUsageSeverityEnum.Warning || usageType.severity === "error";

  const showUpgradeLink =
    usageType.severity === IUsageSeverityEnum.Warning || usageType.severity === "error";

  return (
    <Alert
      severity={usageType.severity}
      action={
        showUpgradeLink ? (
          <Link
            color="text.primary"
            component={RouterLink}
            to={"/organizacao/faturamento"}
            variant="subtitle2"
          >
            <Button variant="contained" size="small">
              Atualize agora
            </Button>
          </Link>
        ) : null
      }
    >
      <AlertTitle>{usageType.title}</AlertTitle>
      {usageType.predescription}
      {usageType.description}
      {warningOrDanger && <Box mt={1}>{usageType?.consequence}</Box>}
    </Alert>
  );
};
