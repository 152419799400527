import { Avatar, Badge, Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { approvalEditSlice } from "src/features/approval-edit/approvalEditSlice";

interface IChatCustomerSelectorProps {}

export const ChatCustomerSelector: React.FC<IChatCustomerSelectorProps> = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  // Seleciona o cliente atual e a lista de clientes
  const selectedChatCustomer = useAppSelector(
    approvalEditSlice.selectors.selectedApprovalStageCustomer,
  );
  const chatCustomers = useAppSelector(approvalEditSlice.selectors.chatCustomers);
  const state = useAppSelector((state) => state.approvalEdit);

  function handleChatCustomerClick(asc_id: string) {
    dispatch(approvalEditSlice.actions.setSelectedChatCustomer({ asc_id }));
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {chatCustomers.map(({ commentsQuantity, approvalStageCustomer }) => (
        <Badge
          badgeContent={commentsQuantity}
          color="primary"
          key={`chat-customer-${approvalStageCustomer.asc_id}`}
          overlap="circular"
        >
          <Stack onClick={() => handleChatCustomerClick(approvalStageCustomer.asc_id)}>
            <RenderAvatar
              key={`chat-customer-${approvalStageCustomer.customer.cust_id}`}
              name={approvalStageCustomer.customer.cust_name}
              src={approvalStageCustomer.customer.cust_photo?.ast_url}
              sx={{
                cursor: "pointer",
                border:
                  selectedChatCustomer?.asc_id === approvalStageCustomer.asc_id
                    ? `2px solid ${theme.palette.primary.main}`
                    : null,
                transition: "border 0.3s ease-in-out",
              }}
            />
            <Typography
              variant="caption"
              color="text.secondary"
              textAlign={"center"}
              maxWidth={40}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {approvalStageCustomer.customer.cust_name}
            </Typography>
          </Stack>
        </Badge>
      ))}
    </Stack>
  );
};
