import React, { useState } from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react"; // React Data Grid Component
import { ColDef } from "ag-grid-community";
import { Box, Card, CardActions, CardHeader, Stack, useTheme } from "@mui/material";
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
interface AgGridReactDefaultProps<T> extends AgGridReactProps<T> {
  actions?: React.ReactNode;
}

export const AgGridReactDefault = <T,>(props: AgGridReactDefaultProps<T>) => {
  const theme = useTheme();

  return (
    // wrapping container with theme & size
    <Card sx={{ display: "flex", flexDirection: "column", flexGrow: 1, height: "100%" }}>
      {/* {props.header && <CardHeader title={props.header} />} */}
      <CardActions>{props.actions}</CardActions>
      <Stack
        className={theme.palette.mode === "dark" ? "ag-theme-material-dark" : "ag-theme-material"}
        sx={{ flexGrow: 1, minHeight: 500 }}
      >
        <AgGridReact<T>
          {...props}
          loadingOverlayComponent={"customLoadingOverlay"}
          overlayLoadingTemplate='<span class="ag-overlay-loading-center">Carregando...</span>'
          localeText={AG_GRID_LOCALE_BR}
        />
      </Stack>
    </Card>
  );
};
