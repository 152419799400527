import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Chip } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  IApprovalItemComment,
  IApprovalItemCommentAicByEnum,
} from "../../../services/thumbz-base-api";

export interface ChatBubbleProps {
  comment: IApprovalItemComment;
}

export function ChatBubble({ comment }: ChatBubbleProps): React.JSX.Element {
  const name =
    comment.aic_by === IApprovalItemCommentAicByEnum.Customer
      ? comment.customer?.cust_name
      : comment.user?.usu_name;
  const avatar =
    comment.aic_by === IApprovalItemCommentAicByEnum.Customer
      ? comment.customer?.cust_photo?.ast_url
      : comment.user?.usu_photo.ast_url;
  const timeAgo = formatDistanceToNow(new Date(comment.aic_created_at), {
    addSuffix: true,
    locale: ptBR,
  });

  const commentText = comment.aic_comment;

  const direction =
    comment.aic_by === IApprovalItemCommentAicByEnum.Customer ? "row" : "row-reverse";

  if (comment.aic_by === IApprovalItemCommentAicByEnum.System) {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        alignSelf={"center"}
        alignContent={"center"}
        justifyContent={"center"}
      >
        <Chip size="small" label={commentText ?? ""} color={comment.aic_color ?? undefined} />
        <Typography pl={2} mt={0.25} variant="caption" color="text.secondary">
          {timeAgo}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction={direction} spacing={2}>
      <Avatar src={avatar ?? undefined} />
      <Stack flex={1}>
        <Card elevation={4} variant="outlined" style={{ borderWidth: 2, borderRadius: 8 }}>
          <CardContent style={{ padding: 10 }}>
            <Stack direction="column" spacing={1}>
              <Typography variant="body2" color="text.primary">
                <strong>{name}</strong>
              </Typography>
              <Typography variant="body2" color="text.primary">
                {commentText}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <Typography pl={2} mt={0.25} variant="caption" color="text.secondary">
          {timeAgo}
        </Typography>
      </Stack>
    </Stack>
  );
}
