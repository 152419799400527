import {
  Box,
  Typography,
  Stack,
  LinearProgress,
  Chip,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { useBillingStatus } from "src/hooks/use-billing";

export const CurrentSubscriptionCard = () => {
  const theme = useTheme();
  const { data, query } = useBillingStatus();

  const { used_workspaces, total_workspaces, available_workspaces, stripe_details, plan } = data;

  const currentPlan = {
    name: plan.label,
    price: stripe_details?.totalAmount ?? 0,
    workspaces: total_workspaces ?? 0,
    pricePerWorkspace: stripe_details?.amountPaid ?? 0,
    nextBillingDate: stripe_details?.nextBillingDate
      ? new Date(stripe_details.nextBillingDate).toLocaleDateString("pt-BR")
      : "-",
    usedWorkspaces: used_workspaces ?? 0,
    totalWorkspaces: total_workspaces ?? 0,
    availableWorkspaces: available_workspaces ?? 0,
    status: "Ativa", // opcional: pode vir do backend como `stripe_details.status.label`
  };

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          position: "relative",
        }}
      >
        <Chip
          label={currentPlan.status}
          color="success"
          size="small"
          variant="outlined"
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
            backgroundColor: theme.palette.success.lightest,
          }}
        />
        <Stack
          flex={1}
          spacing={2}
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <Stack spacing={1}>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Sua Assinatura Atual
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {currentPlan.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2" color="text.secondary" fontWeight={"bold"}>
                Valor Mensal
              </Typography>
              <Stack direction="row" alignItems="center" spacing={0}>
                <Typography variant="h5">R$ {currentPlan.price.toFixed(2)}</Typography>
                <Typography align="center" variant="body2" sx={{ pt: 1 }}>
                  /mês
                </Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {currentPlan.workspaces} workspaces × R$ {currentPlan.pricePerWorkspace.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body2" color="text.secondary" fontWeight={"bold"}>
              Próxima Cobrança
            </Typography>
            <Typography variant="h6">{currentPlan.nextBillingDate}</Typography>
          </Stack>
          <Stack spacing={0} alignItems="flex-start">
            <Stack
              spacing={0}
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography variant="body2" fontWeight="bold" mr={1}>
                Uso de Workspaces
              </Typography>
              <Typography variant="body2">
                {currentPlan.usedWorkspaces} de {currentPlan.totalWorkspaces}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              my={0.5}
              flex={1}
              sx={{ width: "100%" }} // Ensure it grows to meet the parent
            >
              <LinearProgress
                variant="determinate"
                value={(currentPlan.usedWorkspaces / currentPlan.totalWorkspaces) * 100}
                sx={{ width: "100%", height: 8, borderRadius: 4 }}
              />
            </Stack>
            <Typography variant="body2" color="text.secondary">
              {currentPlan.totalWorkspaces - currentPlan.usedWorkspaces} workspace disponível
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
