import React, { useEffect, useState } from "react";
import { Grid, Stack, Skeleton, useTheme } from "@mui/material";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";
import { EmptyPlaceholder } from "../components/EmptyPlaceholder";
import {
  approvalCreateSlice,
  approvalThunks,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useThumbzApi } from "src/services/thumbz-api";
import { IApproval } from "src/services/thumbz-base-api";
import { InstagramCarouselApprovalItemCard } from "src/components/instagram-carousel-post";
import toast from "react-hot-toast";
import { ApprovalAddFile } from "./approval-add-file";
import { Lightbulb } from "@mui/icons-material";
import { GenericHint } from "src/components/hint/generic-hint";
import { validateFiles } from "src/utils/validate-files";

interface IApprovalUpload {
  approval: IApproval;
}

export const ApprovalUpload: React.FC<IApprovalUpload> = ({ approval }) => {
  const { palette } = useTheme();
  const [isCombineEnable, setIsCombineEnable] = useState<boolean>(false);
  const isLoading = useAppSelector(approvalCreateSlice.selectors.isStep1Loading);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isFileDraggingAndDrop, setIsFileDraggingAndDrop] = useState<boolean>(false);
  const { api } = useThumbzApi();
  const dispatch = useAppDispatch();
  const approvalItems = useAppSelector((state) => [...state.approvalCreate.approvalItems]);
  const [isDraggingItem, setIsDraggingItem] = useState(false);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isFileDraggingAndDrop) {
      requestAnimationFrame(() => setIsFileDraggingAndDrop(true));
    }
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // Ensure the event does not fire if mouse is inside the drop area
    const relatedTarget = event.relatedTarget as HTMLElement;
    if (!relatedTarget || event.currentTarget.contains(relatedTarget)) {
      return; // Do nothing if the mouse is still inside the drop zone
    }

    setTimeout(() => {
      requestAnimationFrame(() => setIsFileDraggingAndDrop(false));
    }, 100);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setIsFileDraggingAndDrop(false); // Hide immediately after dropping

    const fileList = validateFiles(event.dataTransfer.files);
    if (!fileList) return;

    dispatch(
      approvalCreateSlice.actions.handleAddFile({ files: fileList, apv_id: approval.apv_id }),
    );

    toast.success("Arquivos adicionados com sucesso");
  };

  useEffect(() => {
    dispatch(approvalThunks.fetchApprovalItems({ apv_id: approval.apv_id }));
  }, [approval.apv_id, dispatch]);

  const onBeforeDragStart = () => {
    if (approvalItems.length > 1) {
      setIsDraggingItem(true);
      document.body.style.cursor = "grabbing";
    }
  };

  const onDragEnd = (result: DropResult) => {
    setIsDraggingItem(false);
    document.body.style.cursor = "default";
    const { destination, source, combine } = result;

    if (!destination && !combine) return;

    if (combine && isCombineEnable) {
      const combineIndex = approvalItems.findIndex((item) => item.item_id === combine.draggableId);

      if (combineIndex !== -1) {
        dispatch(
          approvalCreateSlice.actions.mergeApprovalItems({
            combineIndex,
            sourceIndex: source.index,
            destinationIndex: combineIndex,
          }),
        );
      }
    } else if (destination) {
      dispatch(
        approvalCreateSlice.actions.reorderApprovalItems({
          sourceIndex: source.index,
          destinationIndex: destination.index,
        }),
      );
    }
  };

  const _handleAddFile = async (files: FileList | null) => {
    const validFiles = validateFiles(files);
    if (!validFiles) return;

    dispatch(
      approvalCreateSlice.actions.handleAddFile({ files: validFiles, apv_id: approval.apv_id }),
    );
  };

  const handleUnmergeAll = (index: number) => {
    dispatch(approvalCreateSlice.actions.unmergeAllFromSingleApprovalItem(index));
  };

  const handleUnmergeSingle = (approvalItemIndex: number, assetsIndex: number) => {
    dispatch(
      approvalCreateSlice.actions.unmergeSingleAssetsFromApprovalItem({
        approvalItemIndex,
        assetsIndex,
      }),
    );
  };

  if (!approvalItems) {
    return <div>Approval items not found</div>;
  }

  const hintText = "Dica: Você pode arrastar e soltar os arquivos para reordená-los.";

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={-2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: palette.background.paper,
          borderRadius: 1,
          padding: 2,
        }}
      >
        <GenericHint text={hintText} IconAsset={Lightbulb} />
        <ApprovalAddFile onAddFile={_handleAddFile} disabled={isLoading} isPending={isLoading} />
      </Stack>
      <div
        style={{
          position: "relative", // Ensures the overlay stays inside this div
          border: isFileDraggingAndDrop ? "2px dashed #ccc" : "none",
          padding: "20px",
          borderRadius: "8px",
          textAlign: "left",
          minHeight: "150px",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
      >
        {isFileDraggingAndDrop && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "flex-start", // Aligns the content at the top
              justifyContent: "center", // Centers horizontally
              color: "#fff",
              zIndex: 10,
              pointerEvents: "none",
              borderRadius: "8px",
              paddingTop: "250px", // Moves the text down by 100px
            }}
          >
            <h1>Solte os arquivos</h1>
          </div>
        )}

        {approvalItems.length === 0 && !isLoading && (
          <EmptyPlaceholder
            overlineText="Arraste e solte arquivos aqui"
            customSubtitle='ou clique no botão "+ Arquivos" para fazer upload'
            showButton={false}
          />
        )}

        {approvalItems.length === 0 && isLoading ? (
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton variant="rounded" width="100%" height={200} animation="wave" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
            <Droppable
              droppableId="approvalItems"
              direction="vertical"
              isCombineEnabled={isCombineEnable}
              isDropDisabled={approvalItems.length <= 1}
            >
              {(provided) => (
                <Grid container spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                  {approvalItems.map((approvalItem, index) => {
                    const approvalStageCustomers = approval.stages.flatMap(
                      (stage) => stage.approvalStageCustomers,
                    );
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}`}
                        index={index}
                        isDragDisabled={approvalItems.length <= 1}
                      >
                        {(provided, snapshot) => (
                          <Grid
                            item
                            xs={12}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging || snapshot.combineTargetFor ? 0.5 : 1,
                              cursor: isDraggingItem ? "grab" : "default",
                            }}
                          >
                            <span style={{ display: isLoading ? "none" : "inherit" }}>
                              <InstagramCarouselApprovalItemCard
                                key={approvalItem.item_id}
                                approvalItem={approvalItem}
                                onUnmergeAll={() => handleUnmergeAll(index)}
                                onUnmergeSingle={(assetsIndex) =>
                                  handleUnmergeSingle(index, assetsIndex)
                                }
                                approvalStageCustomers={approvalStageCustomers}
                                enableThumbnail={true}
                                apv_status={approval.apv_status}
                              />
                            </span>
                            <Skeleton
                              style={{ display: isLoading ? "inherit" : "none" }}
                              variant="rounded"
                              width="100%"
                              height={200}
                              animation="wave"
                            />
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </Stack>
  );
};
