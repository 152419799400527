import type { FC, ReactNode } from "react";
import { useState } from "react";
import PropTypes from "prop-types";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import { Check } from "@mui/icons-material";

import { useBillingStatus } from "src/hooks/use-billing";
import { IAvailablePlansDto } from "src/services/thumbz-base-api";

interface Plan {
  id: string;
  icon: ReactNode;
  name: string;
  price: number;
  interval: string; // 'month' or 'year'
}

interface Invoice {
  id: string;
  amount: number;
  createdAt: number;
}

interface AccountBillingSettingsProps {
  plan?: string;
  invoices?: Invoice[];
}

export const AccountBillingSettings: FC<AccountBillingSettingsProps> = () => {
  const { data, portalLink, billing: billingData } = useBillingStatus();
  const { monthlyPlans, yearlyPlans } = billingData;
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const currentPlanSlug = data.plan?.slug;
  const stripeDetails = data.stripe_details;

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function renderPlanCard(plan: IAvailablePlansDto) {
    const isCurrent = plan.is_current_plan;
    const priceMeta = plan.product.stripe_metadata?.stripe_price_metadata;
    const currency = priceMeta?.currency ?? "brl";
    const interval = priceMeta?.recurring?.interval ?? "month";

    // Pegando o valor custom que veio do backend
    const _amount = plan.product.stripe_metadata.stripe_price_metadata.tiers[0] as unknown;
    const amount =
      typeof _amount === "object" &&
      _amount !== null &&
      "unit_amount" in _amount &&
      typeof (_amount as { unit_amount: unknown }).unit_amount === "number"
        ? (_amount as { unit_amount: number }).unit_amount
        : null;

    const priceLabel =
      amount !== null ? formatPrice(amount, currency, "pt-BR") : "Preço sob consulta";

    return (
      <Grid item key={plan.product.stp_id} xs={12} sm={6}>
        <Card
          variant="outlined"
          sx={{
            borderRadius: 2,
            borderColor: isCurrent ? "primary.main" : "#E5E7EB",
            borderWidth: isCurrent ? 2 : 1,
            boxShadow: "none",
            height: "100%",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Stack spacing={2} alignItems="center" mb={2}>
              {plan.product.stripe_metadata.stripe_product_metadata.images.map((image) => (
                <Avatar
                  variant="rounded"
                  sx={{
                    height: 64,
                    width: 64,
                    mb: 1,
                  }}
                  key={image}
                  src={image}
                  alt={plan.product.stripe_metadata.stripe_product_metadata.name}
                />
              ))}

              <Typography variant="h5" fontWeight="bold">
                {plan.product.stripe_metadata.stripe_product_metadata.name}
              </Typography>

              <Box display="flex" alignItems="baseline">
                <Typography variant="h5" fontWeight="bold">
                  {priceLabel}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ ml: "4px" }}>
                  /workspace/{interval === "month" ? "mês" : "ano"}
                </Typography>
              </Box>
            </Stack>

            <Box mb={2}>
              {plan.product.stripe_metadata.stripe_product_metadata.features.map(({ name }) => (
                <Typography key={name} variant="body2" sx={{ mb: 1 }} color="text.primary">
                  - {name}
                </Typography>
              ))}
            </Box>

            <Box mt="auto">
              <Button
                color="primary"
                variant={!isCurrent ? "contained" : "outlined"}
                disabled={portalLink.isLoading}
                size="small"
                fullWidth
                href={portalLink.data}
              >
                {isCurrent ? "Plano Atual" : "Mudar de Plano"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Stack spacing={2}>
      {/* <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          backgroundColor: theme.palette.divider,
          borderRadius: "8px",
          width: "fit-content",
          padding: 1,
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTab-root": {
            textTransform: "none",
            minWidth: "200px",
            borderRadius: "6px",
            color: theme.palette.text.secondary,
            fontWeight: 600,
            fontSize: 14,
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.background.paper,
            color: "#111827",
            boxShadow: `0 0 0 1px ${theme.palette.divider}`,
          },
        }}
      >
        <Tab label="Planos Mensais" />
        <Tab label="Planos Anuais" />
      </Tabs> */}

      <Grid container spacing={0} minHeight={400}>
        {monthlyPlans.map((plan) => renderPlanCard(plan))}
        {/* {value === 0 && monthlyPlans.map((plan) => renderPlanCard(plan))} */}
        {/* {value === 1 &&
          (yearlyPlans.length > 0 ? (
            yearlyPlans.map((plan) => renderPlanCard(plan))
          ) : (
            <Grid item xs={12}>
              <Stack sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  Planos anuais com desconto em breve!
                </Typography>
              </Stack>
            </Grid>
          ))} */}
      </Grid>
    </Stack>
  );
};

AccountBillingSettings.propTypes = {
  plan: PropTypes.string,
  invoices: PropTypes.array,
};

// Updated formatPrice to support "R$ 9,00" style (Brazilian Portuguese)
export function formatPrice(amountInCents: number, currency: string, locale: string) {
  const amount = amountInCents / 100;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
}
