import { useMutation, useQueryClient } from "@tanstack/react-query";
import { thumbzApi } from "src/services/thumbz-api";
import { useBillingStatus } from "./use-billing";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useConfirm } from "material-ui-confirm";
import { isPending } from "@reduxjs/toolkit";

export function useDeleteWorkspace({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { refetchAllQueries } = useBillingStatus();

  const mutation = useMutation({
    mutationFn: thumbzApi.workspace.workspaceControllerDelete,
    onSuccess: (_, { wrk_id }: { wrk_id: string }) => {
      refetchAllQueries();
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey.some(
            (key) => typeof key === "string" && key.includes("workspace") && key.includes(wrk_id),
          );
        },
      });
      toast.success("Workspace excluído com sucesso!");
      onSuccess?.();
    },
    onError: (error) => {
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message));
      onError?.(error);
    },
  });

  function handleDelete({ wrk_id }: { wrk_id: string }) {
    confirm({
      title: "Excluir espaço de trabalho",
      description: "Tem certeza de que deseja excluir este espaço de trabalho?",
      confirmationText: "Excluir",
      cancellationText: "Cancelar",
    })
      .then(() => {
        mutation.mutate({ wrk_id });
      })
      .catch((error) =>
        getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
      );
  }

  return {
    handleDelete,
    isPending: isPending(mutation),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error,
  };
}
