import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { ISliceItemUpsertDto } from "../approvalsCreate/approvalCreateSlice";
import { thumbzApi } from "../../services/thumbz-api";
import {
  IApproval,
  IUser,
  IApprovalItemComment,
  IApprovalItemCommentAicByEnum,
  ICommentAddRequestDtoAicByEnum,
  IApprovalItemUpsertDto,
  IApprovalItem,
} from "../../services/thumbz-base-api";
import { createFormData } from "../../utils/create-form-data";
import { getMimetype } from "../../utils/get-mimetype";
import { approvalEditSelectors } from "./approval-edit-selectors";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { IHandleChange } from "src/components/approval/field-renderer";
import { addHours } from "date-fns";

export enum ApprovalEditView {
  GROUP = "group",
  LIST = "list",
}

export enum ApprovalEditSort {
  ASC = "asc",
  DESC = "desc",
}

export enum ApprovalEditFilter {
  ALL = "all",
  PENDING = "pending",
  PAID = "paid",
  CANCELED = "canceled",
}

export interface ApprovalEditState {
  approval: IApproval | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;

  view: ApprovalEditView;
  sort: ApprovalEditSort;
  filter: ApprovalEditFilter;

  currentPage: number;
  rowsPerPage: number;

  selected_item_id: string | null;
  selected_chat_asc_id: string | null;
  draft: boolean;
}

const initialState: ApprovalEditState = {
  approval: null,
  error: null,
  status: "idle",
  view: ApprovalEditView.GROUP,
  sort: ApprovalEditSort.ASC,
  filter: ApprovalEditFilter.ALL,
  currentPage: 1,
  rowsPerPage: 5,

  selected_item_id: null,
  selected_chat_asc_id: null,
  draft: false,
};

const fetchApproval = createAsyncThunk(
  "approval/fetchApproval",
  async ({ apv_id }: { apv_id: string }) => {
    return await thumbzApi.approval.approvalControllerGet(apv_id);
  },
);

const deleteApprovalItemAsset = createAsyncThunk(
  "approvalItem/deleteApprovalItemAsset",
  async ({ aia_id }: { aia_id: string }, { dispatch, rejectWithValue }) => {
    try {
      await thumbzApi.approvalItem.approvalItemControllerDeleteItemAsset({
        aia_id,
      });
      return { aia_id };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addComment = createAsyncThunk(
  "approvalItemRevision/addComment",
  async (
    { comment, loggedUser, air_id }: { comment: string; loggedUser: IUser; air_id: string },
    { dispatch, getState, rejectWithValue },
  ) => {
    const aic_id = uuidv4(); // Gerando um ID para o comentário otimista

    const state = getState() as { approvalEdit: ApprovalEditState };
    const selectedRevision = approvalEditSelectors.getRevision(state.approvalEdit);
    if (!selectedRevision) {
      return rejectWithValue("No revision selected");
    }

    const optimisticComment: IApprovalItemComment = {
      aic_id, // Usando o ID gerado
      aic_comment: comment,
      aic_by: IApprovalItemCommentAicByEnum.User,
      aic_created_at: new Date().toISOString(),
      aic_updated_at: new Date().toISOString(),
      aic_deleted_at: null,
      revision: selectedRevision,
      customer: null,
      user: loggedUser,
      aic_color: undefined,
    };

    // Adicionando o comentário de forma otimista no estado
    dispatch(approvalEditSlice.actions.optimisticAddComment({ comment: optimisticComment }));

    try {
      const response =
        await thumbzApi.approvalItemRevision.approvalItemRevisionControllerCreateComment({
          air_id,
          comment: {
            aic_comment: comment,
            aic_by: ICommentAddRequestDtoAicByEnum.User,
            usu_id: loggedUser.usu_id,
            cust_id: null,
          },
        });

      return { aic_id, data: response };
    } catch (error) {
      return rejectWithValue({ error, aic_id });
    }
  },
);

const upsertApprovalItem = createAsyncThunk(
  "approvalItems/upsertApprovalItem",
  async (data: ISliceItemUpsertDto, { dispatch, rejectWithValue }) => {
    try {
      const formData = createFormData({
        apv_id: data.apv_id,
        assets: data.assets.map((asset) => ({
          aia_id: String(asset.aia_id),
          aia_order: asset.aia_order,
          file: asset.file ?? undefined,
          props: {
            mimetype: asset.props?.mimetype ?? getMimetype(asset.file?.type),
          },
        })),
        item_description: data.item_description ?? "",
        item_order: data.item_order ?? 999,
        item_id: data.item_id,
      });

      const responsesPromiseArr = data.responses.map((response) =>
        thumbzApi.approvalResponse.approvalResponseControllerUpsertApprovalResponse(response),
      );

      await Promise.all(responsesPromiseArr);

      const response = await thumbzApi.instance.post<
        IApprovalItemUpsertDto,
        AxiosResponse<IApprovalItem>
      >(`/approval-item/upsert`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    } catch (error) {
      console.error("🚀 ~ error:", error);
      return rejectWithValue(error);
    }
  },
);

const changeStatus = createAsyncThunk(
  "approval/changeStatus",
  async ({ apv_id, sts_id }: { apv_id: string; sts_id: string }) => {
    return await thumbzApi.approval.approvalControllerChangeStatus({
      sts_id,
      apv_id,
    });
  },
);

export const approvalEditSlice = createSlice({
  name: "approvalEdit",
  initialState,
  selectors: {
    view: approvalEditSelectors.view,
    sort: approvalEditSelectors.sort,
    error: approvalEditSelectors.error,
    status: approvalEditSelectors.status,

    currentPage: approvalEditSelectors.currentPage,
    rowsPerPage: approvalEditSelectors.rowsPerPage,

    approval: approvalEditSelectors.getApproval,
    workspace: approvalEditSelectors.getWorkspace,

    currentStage: approvalEditSelectors.currentStage,
    currentStageIndex: approvalEditSelectors.currentStageIndex,
    shouldShowStageStepper: approvalEditSelectors.shouldShowStageStepper,
    allStages: approvalEditSelectors.allStages,
    isStageCompleted: (state: ApprovalEditState) => (stg_id: string) =>
      approvalEditSelectors.isStageCompleted(state, stg_id),
    stageCompletedPercentage: (state: ApprovalEditState) => (stg_id: string) =>
      approvalEditSelectors.stageCompletedPercentage(state, stg_id),

    approvalItemsGrouped: approvalEditSelectors.approvalItemsGrouped,

    selectedApprovalItem: approvalEditSelectors.selectedApprovalItem,
    allComments: approvalEditSelectors.getCommentsFromSelectedApprovalItem,

    selectedApprovalStageCustomer: approvalEditSelectors.getApprovalStageCustomer,
    chatCustomers: approvalEditSelectors.getChatCustomers,
    chatComments: approvalEditSelectors.getCommentsFromSelectedCustomer,
    countCommentsFromSelectedCustomer: approvalEditSelectors.countCommentsFromSelectedCustomer,
    selectedChatAirId: approvalEditSelectors.getRevision,

    countStageStatus: approvalEditSelectors.countStageStatus,

    isDraft: (state: ApprovalEditState) => state.draft,
  },
  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },

    selectApprovalItem: (state, action: PayloadAction<{ item_id: string }>) => {
      state.selected_item_id = action.payload.item_id;
    },
    unselectApprovalItem: (state) => {
      state.selected_item_id = null;
    },
    setSelectedChatCustomer: (state, action: PayloadAction<{ asc_id: string }>) => {
      state.selected_chat_asc_id = action.payload.asc_id;
    },
    changeDescription: (state, action: PayloadAction<{ item_id: string; description: string }>) => {
      const { item_id, description } = action.payload;
      const approvalItem = state?.approval?.approvalItems.find((item) => item.item_id === item_id);
      if (approvalItem) {
        approvalItem.item_description = description;
        state.draft = true;
      }
    },
    reorderApprovalItemAsset: (
      state,
      action: PayloadAction<{
        destinationIndex: number;
        sourceIndex: number;
      }>,
    ) => {
      const item_id = state.selected_item_id;
      const { destinationIndex, sourceIndex } = action.payload;

      if (!item_id) return;

      const approvalItem = state.approval?.approvalItems.find((item) => item.item_id === item_id);
      if (!approvalItem) return;

      const [removed] = approvalItem.approvalItemAsset.splice(sourceIndex, 1);
      approvalItem.approvalItemAsset.splice(destinationIndex, 0, removed);

      const newOrder = approvalItem.approvalItemAsset.map((approvalItemAsset, index) => ({
        ...approvalItemAsset,
        aia_order: index,
      }));

      approvalItem.approvalItemAsset = newOrder;

      state.draft = true;
    },

    optimisticAddComment: (state, action: PayloadAction<{ comment: IApprovalItemComment }>) => {
      const { comment } = action.payload;
      const selectedRevision = approvalEditSelectors.getRevision(state);
      if (!selectedRevision) return;

      // Use date-fns to add 6 hours to the current time
      const adjustedTime = addHours(new Date(), 6);
      const isoDate = adjustedTime.toISOString();

      const _comment = {
        ...comment,
        aic_created_at: isoDate,
        aic_updated_at: isoDate,
      };

      if (selectedRevision.air_comments) {
        selectedRevision.air_comments.push(_comment);
        // Re-sort the array so that the oldest comment is at the top and the newest at the bottom
        selectedRevision.air_comments.sort(
          (a, b) => new Date(a.aic_created_at).getTime() - new Date(b.aic_created_at).getTime(),
        );
      } else {
        selectedRevision.air_comments = [_comment];
      }

      state.draft = true; // Mark as draft
    },

    // Ação para remover o comentário em caso de erro
    removeComment: (state, action: PayloadAction<{ aic_id: string }>) => {
      const { aic_id } = action.payload;
      const selectedRevision = approvalEditSelectors.getRevision(state);
      if (!selectedRevision || !selectedRevision.air_comments) return;

      // Filtrando o comentário pelo ID para removê-lo
      selectedRevision.air_comments = selectedRevision.air_comments.filter(
        (comment) => comment.aic_id !== aic_id,
      );
    },

    updateResponses: (state, action: PayloadAction<IHandleChange>) => {
      const { atf_id, apr_value, apr_id, apv_id, item_id } = action.payload;

      const selected = approvalEditSlice.selectors.selectedApprovalItem({ approvalEdit: state });
      if (!selected) return;

      const response = selected?.responses?.find((r) => r.approvalTypeField.atf_id === atf_id);
      if (!response) return;

      response.apr_value = apr_value;

      state.draft = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApproval.pending, (state) => {
        state.status = "loading";
      })

      .addCase(fetchApproval.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approval = action.payload;
      })

      .addCase(fetchApproval.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      });

    builder
      .addCase(upsertApprovalItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(upsertApprovalItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedApprovalItem = action.payload.data; // Acessa o item retornado pelo backend

        if (state.approval) {
          const index = state.approval.approvalItems.findIndex(
            (item) => item.item_id === updatedApprovalItem.item_id,
          );

          if (index !== -1) {
            // Substitui o item específico na posição correta
            state.approval.approvalItems[index] = updatedApprovalItem;
          } else {
            // Se o item não foi encontrado, adiciona-o
            state.approval.approvalItems.push(updatedApprovalItem);
          }

          state.draft = false;
        }
      })
      .addCase(upsertApprovalItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
        toast.error("Erro ao salvar item de aprovação");
      });

    // deleteApprovalItemAsset
    builder
      .addCase(deleteApprovalItemAsset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteApprovalItemAsset.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { aia_id } = action.payload;

        // remove aia_id from state:
        const approvalItem = state.approval?.approvalItems.find((item) =>
          item.approvalItemAsset.some((asset) => asset.aia_id === aia_id),
        );

        if (!approvalItem) return;

        approvalItem.approvalItemAsset = approvalItem.approvalItemAsset.filter(
          (asset) => asset.aia_id !== aia_id,
        );

        toast.success("Item de aprovação deletado com sucesso");
      })
      .addCase(deleteApprovalItemAsset.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
        getServerErrorArrayMessage(action.payload).forEach((message) => toast.error(message));
      });

    builder
      .addCase(addComment.fulfilled, (state, action) => {
        // No caso de sucesso, o comentário já foi adicionado otimisticamente, nada a fazer aqui
      })

      .addCase(addComment.rejected, (state, action) => {
        // Remover o comentário otimista em caso de erro
        const { aic_id } = action.payload as { aic_id: string };
        state.status = "failed";
        state.error = action.error.message ?? null;

        // Remove o comentário com base no ID gerado
        const selectedRevision = approvalEditSelectors.getRevision(state);
        if (!selectedRevision || !selectedRevision.air_comments) return;

        selectedRevision.air_comments = selectedRevision.air_comments.filter(
          (comment) => comment.aic_id !== aic_id,
        );

        toast.error("Erro ao adicionar comentário");
      });

    builder
      .addCase(changeStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approval = action.payload;
      })
      .addCase(changeStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
        toast.error("Erro ao alterar status");
      });
  },
});

export const approvalEditThunks = {
  fetchApproval,
  upsertApprovalItem,
  deleteApprovalItemAsset,
  addComment,
  changeStatus,
};
