import {
  AvatarGroup,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
  Skeleton as MuiSkeleton,
} from "@mui/material";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import CustomerAvatarWithIcons from "src/components/avatar/CustomerAvatarWithIcons";
import { InstagramRenderImgPreview } from "src/components/instagram-render-img-preview";
import { ItemStatus } from "src/components/status/item-status";
import { ColumnDef, DataTable } from "src/components/table/data-table";
import { IGroupedTableRow } from "src/features/approval-edit/approval-edit-selectors";
import {
  approvalEditSlice,
  approvalEditThunks,
  ApprovalEditView,
} from "src/features/approval-edit/approvalEditSlice";
import { useIcon } from "src/hooks/use-icon";
import { Mimetype } from "src/utils/get-mimetype";
import { ApprovalEditDrawer } from "./approval-edit-drawer";
import { Delete, MoreVertOutlined, Refresh } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { removeHTML } from "src/utils/remove-html";
import { Skeleton } from "src/components/upload/skeleton";

const MEDIA_DEFAULT_SIZE = 2;

const columns: ColumnDef<IGroupedTableRow>[] = [
  {
    name: "Material",
    formatter: ({ asset }): React.ReactNode => (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "2.5rem" }}
      >
        <InstagramRenderImgPreview
          file={null}
          mimetype={asset?.ast_mimetype as Mimetype}
          already_uploaded_url={asset?.ast_url || null}
          size={MEDIA_DEFAULT_SIZE * 16}
        />
      </Box>
    ),
  },
  {
    name: "Descrição",
    formatter: ({ item_description }): React.ReactNode => (
      <Tooltip title={removeHTML(item_description)}>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", maxWidth: "10rem" }}>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center", // 🔹 Centraliza o texto dentro da célula
              flex: 1,
            }}
          >
            {removeHTML(item_description)}
          </Typography>
        </Box>
      </Tooltip>
    ),
  },
  {
    name: "Status",
    formatter: ({ status }): React.ReactNode => (
      <Box sx={{ display: "flex", justifyContent: "center", width: "6rem" }}>
        <ItemStatus status={status} />
      </Box>
    ),
  },
  {
    name: "Comentários",
    formatter: ({ commentsQuantity }): React.ReactNode => (
      <Box sx={{ display: "flex", justifyContent: "center", width: "5rem" }}>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {commentsQuantity}
        </Typography>
      </Box>
    ),
  },
  {
    name: "Ordem",
    formatter: ({ item_order }): React.ReactNode => (
      <Box sx={{ display: "flex", justifyContent: "center", width: "5rem" }}>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {item_order}
        </Typography>
      </Box>
    ),
  },
  {
    name: "Aprovações",
    formatter: ({ customersApprovals }): React.ReactNode => (
      <Box sx={{ display: "flex", justifyContent: "center", minWidth: "6rem", flexGrow: 1 }}>
        <AvatarGroup
          max={3}
          sx={{
            "& .MuiAvatar-root": { width: "1.8rem", height: "1.8rem" },
          }}
        >
          {customersApprovals.map(({ air_status, customer }, index) => {
            const icon = useIcon({ air_status });
            return (
              <CustomerAvatarWithIcons
                customer={customer}
                showIcon
                icon={icon}
                key={`avatar-qty-${customer.cust_id}-${index}`}
                showLegend={false}
                size={MEDIA_DEFAULT_SIZE * 12}
              />
            );
          })}
        </AvatarGroup>
      </Box>
    ),
  },
  {
    name: "Ações",
    align: "center",
    formatter(params) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PopupState variant="popover" popupId={`approval-item-actions-${params.item_id}`}>
            {(popupState) => (
              <IconButton {...bindTrigger(popupState)}>
                <MoreVertOutlined />
              </IconButton>
            )}
          </PopupState>
        </Box>
      );
    },
  },
];

interface ApprovalTableSectionProps {
  title: string;
  rows: IGroupedTableRow[];
  emptyMessage: string;
  handleRowClick: ({ item_id }: { item_id: string }) => void;
  isLoading: boolean;
}

const ApprovalTableSection: React.FC<ApprovalTableSectionProps> = ({
  title,
  rows,
  emptyMessage,
  handleRowClick,
  isLoading,
}) => {
  function _handleRowClick(event: React.MouseEvent, row: IGroupedTableRow) {
    handleRowClick({ item_id: row.item_id });
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        {title} ({rows.length})
      </Typography>

      {isLoading ? (
        <Stack spacing={1}>
          <MuiSkeleton variant="rectangular" height={50} />
          {[...Array(3)].map((_, index) => (
            <MuiSkeleton variant="rounded" height={60} key={`skeleton-${index}`} />
          ))}
        </Stack>
      ) : (
        <DataTable<IGroupedTableRow>
          columns={columns}
          rows={rows}
          selectable={false}
          selected={new Set<string>()}
          uniqueRowId={(row) => row.item_id}
          dense
          emptyMessage={emptyMessage}
          onClick={_handleRowClick}
        />
      )}
    </Stack>
  );
};

export const ApprovalItemsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const approval = useAppSelector(approvalEditSlice.selectors.approval);
  const groupedItems = useAppSelector(approvalEditSlice.selectors.approvalItemsGrouped);
  const currentStage = useAppSelector(approvalEditSlice.selectors.currentStage);
  const view = useAppSelector(approvalEditSlice.selectors.view);
  const status = useAppSelector(approvalEditSlice.selectors.status);
  const isLoading = status === "loading";

  function handleRowClick({ item_id }: { item_id: string }) {
    dispatch(approvalEditSlice.actions.selectApprovalItem({ item_id }));
  }

  return (
    <>
      <Stack spacing={5}>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Skeleton isLoading={isLoading}>
            <Typography variant="h5">{currentStage?.stg_name}</Typography>
          </Skeleton>
          {/* <ApprovalTableFilters /> */}
          {approval?.apv_id && (
            <IconButton
              disabled={isLoading}
              onClick={() => {
                dispatch(approvalEditThunks.fetchApproval({ apv_id: approval?.apv_id }));
              }}
            >
              <Refresh />
            </IconButton>
          )}
        </Stack>

        {view === ApprovalEditView.GROUP ? (
          <Stack spacing={4}>
            <ApprovalTableSection
              title="Pendentes"
              emptyMessage="Por enquanto, não há itens pendentes."
              rows={groupedItems.pending}
              handleRowClick={handleRowClick}
              isLoading={isLoading}
            />
            <ApprovalTableSection
              title="Rejeitados"
              emptyMessage="Por enquanto, não há itens rejeitados."
              rows={groupedItems.rejected}
              handleRowClick={handleRowClick}
              isLoading={isLoading}
            />
            <ApprovalTableSection
              title="Aprovados"
              emptyMessage="Por enquanto, não há itens aprovados."
              rows={groupedItems.approved}
              handleRowClick={handleRowClick}
              isLoading={isLoading}
            />
          </Stack>
        ) : (
          <Stack spacing={4}>
            <ApprovalTableSection
              title="Todos os Itens"
              emptyMessage="Por enquanto, não há itens."
              handleRowClick={handleRowClick}
              isLoading={isLoading}
              rows={[...groupedItems.pending, ...groupedItems.approved, ...groupedItems.rejected]}
            />
          </Stack>
        )}
      </Stack>
      <ApprovalEditDrawer />
    </>
  );
};

export default ApprovalItemsTable;
