import { IBillingTierDto } from "src/services/thumbz-base-api";

export function getTieredUnitPrice(workspaceCount: number, tiers: IBillingTierDto[]) {
  for (const tier of tiers) {
    const upTo = String(tier.up_to) === "∞" ? Infinity : Number(tier.up_to);
    if (workspaceCount <= upTo) {
      return tier.unit_amount ?? 0;
    }
  }
  // fallback (última tier)
  return tiers.at(-1)?.unit_amount ?? 0;
}
