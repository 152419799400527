import { CircularProgress, Dialog, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { queryKeys } from "../../config/query-key";
import { organizationSlice } from "../../features/organization/organizationSlice";
import { EmptyPlaceholder } from "../../sections/components/EmptyPlaceholder";
import { useThumbzApi } from "../../services/thumbz-api";
import { IOrganization } from "../../services/thumbz-base-api";
import { authSlice, signOut } from "src/features/auth/authSlice";
import { SplashScreen } from "src/components/splash-screen";
import toast from "react-hot-toast";

interface OrganizationContextData {
  selectedOrganization: IOrganization | null;
  setSelectedOrganization: (value: IOrganization | null) => void;
  userOrganizations: IOrganization[];
  setUserOrganizations: (value: IOrganization[]) => void;
}

const OrganizationContext = createContext<OrganizationContextData>({} as OrganizationContextData);

interface IOrganizationProviderProps {
  children: React.ReactNode;
}

export const OrganizationProvider: React.FC<IOrganizationProviderProps> = ({ children }) => {
  const { api } = useThumbzApi();
  const queryClient = useQueryClient();
  // const [userOrganizations, setUserOrganizations] = useLocalStorage<IOrganization[]>(
  //   `@${prefix}-organizations`,
  //   []
  // );
  // const [selectedOrganization, setSelectedOrganization] = useLocalStorage<IOrganization | null>(
  //   `@${prefix}-selected-organization`,
  //   null
  // );

  const userOrganizations = useAppSelector((state) => state.organization.userOrganizations);
  const selectedOrganization = useAppSelector((state) => state.organization.selectedOrganization);
  const dispatch = useAppDispatch();

  function setSelectedOrganization(value: IOrganization | null) {
    if (!value) {
      dispatch(organizationSlice.actions.setSelectedOrganization(value));
      return;
    }

    dispatch(organizationSlice.actions.clearSelectedOrganization());
  }

  function setUserOrganizations(value: IOrganization[]) {
    dispatch(organizationSlice.actions.setUserOrganizations(value));
  }

  useEffect(() => {
    const org_id = selectedOrganization?.org_id;
    if (!org_id) return;

    queryClient.prefetchQuery({
      queryKey: queryKeys.approvalTypes.approvalTypeControllerList({ org_id }).queryKey,
      queryFn: () => api.approvalTypes.approvalTypeControllerList({ org_id }),
    });
  }, [api.approvalTypes, queryClient, selectedOrganization]);

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization,
        userOrganizations,
        setSelectedOrganization,
        setUserOrganizations,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export function useOrganization() {
  const user = useAppSelector(authSlice.selectors.selectUser);
  const { api } = useThumbzApi();
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error("useOrganization must be used within an OrganizationProvider");
  }

  const allUserOrganizationsQuery = useQuery({
    queryKey: queryKeys.organization.list({ usu_id: String(user?.usu_id) }).queryKey,
    queryFn: () => api.organization.organizationControllerList({}),
    enabled: !!user?.usu_id,
  });

  useEffect(() => {
    const isSuccess = allUserOrganizationsQuery.isSuccess;
    const isArr = Array.isArray(allUserOrganizationsQuery?.data);
    if (isSuccess && isArr) {
      context?.setUserOrganizations(allUserOrganizationsQuery.data);
    }
  }, [allUserOrganizationsQuery.data, allUserOrganizationsQuery.isSuccess, context]);

  function clear() {
    if (!context) {
      return;
    }

    context.setUserOrganizations([]);
    context.setSelectedOrganization(null);
  }

  return {
    allUserOrganizationsQuery,
    userOrganizations: context.userOrganizations,
    selectedOrganization: context.selectedOrganization,
    setSelectedOrganization: context.setSelectedOrganization,
    clearOrganizationContext: clear,
  };
}

export function OrganizationGuard({ children }: { children: React.ReactNode }) {
  const userOrganizations = useAppSelector((state) => state.organization.userOrganizations);
  const selectedOrganization = useAppSelector((state) => state.organization.selectedOrganization);
  const { clearOrganizationContext, allUserOrganizationsQuery } = useOrganization();

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      dispatch(signOut());
      clearOrganizationContext();
      setOpen(false);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [clearOrganizationContext, dispatch]);

  // Using useEffect to set the organization avoids side effects during render.
  useEffect(() => {
    if (!selectedOrganization?.org_id && userOrganizations.length === 1) {
      const selectedOrg = userOrganizations[0];
      dispatch(organizationSlice.actions.setSelectedOrganization(selectedOrg));
    }
  }, [selectedOrganization, userOrganizations, dispatch]);

  if (allUserOrganizationsQuery.isLoading) {
    return <SplashScreen />;
  }

  if (allUserOrganizationsQuery.isError) {
    // Optional: Delay logout to allow SplashScreen rendering.
    setTimeout(() => {
      handleLogout();
    }, 1000);
    return <SplashScreen />;
  }

  if (!selectedOrganization?.org_id) {
    return <SelectOrganizationModal open={true} setOpen={setOpen} allowClose={false} />;
  }

  return <>{children}</>;
}

interface ISelectOrganizationmodal {
  open: boolean;
  setOpen: (open: boolean) => void;
  children?: React.ReactNode;
  allowClose?: boolean;
}

export const SelectOrganizationModal: React.FC<ISelectOrganizationmodal> = ({
  allowClose = true,
  open,
  setOpen,
}) => {
  const userOrganizations = useAppSelector((state) => state.organization.userOrganizations);
  const selectedOrganization = useAppSelector((state) => state.organization.selectedOrganization);
  const dispatch = useAppDispatch();

  function handleClose() {
    if (allowClose) setOpen(false);
  }

  const hasSelectedOrganization = !!selectedOrganization;
  const overlineText = hasSelectedOrganization
    ? "Você quer mudar de organização?"
    : "Selecione uma organização para continuar";

  const customSubtitle = hasSelectedOrganization
    ? "Selecione uma organização da lista abaixo"
    : "Por favor, selecione um organização para continuar";

  return (
    <Dialog open={open} onClose={handleClose}>
      <EmptyPlaceholder
        overlineText={overlineText}
        customSubtitle={customSubtitle}
        showButton={false}
      >
        <FormControl fullWidth>
          <InputLabel id="select-org-label">Clique para selecionar um organização</InputLabel>
          <Select
            value={selectedOrganization}
            placeholder="Select an organization"
            label="Select an organization"
            fullWidth
            onChange={(event) => {
              const selectedOrg = userOrganizations.find(
                (org) => org.org_id === event.target.value,
              );
              if (!selectedOrg) {
                return;
              }
              dispatch(organizationSlice.actions.setSelectedOrganization(selectedOrg));
              handleClose();
            }}
          >
            {userOrganizations.map((org) => (
              <MenuItem key={org.org_id} value={org.org_id}>
                {org.org_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </EmptyPlaceholder>
    </Dialog>
  );
};

export default OrganizationContext;
