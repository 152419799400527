import { useState, type FC } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RenderAvatar } from 'src/components/avatar/RenderAvatar';
import { ConfirmModal } from 'src/components/modal/confirm-modal';
import { queryKeys } from 'src/config/query-key';
import { authSlice } from 'src/features/auth/authSlice';
import { useThumbzApi } from 'src/services/thumbz-api';
import { IOrganizationCollaborator } from 'src/services/thumbz-base-api';
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message';
import { useUserRole } from 'src/hooks/use-user-roles';

interface OrganizationCollaboratorProps {
  member: IOrganizationCollaborator;
  org_id: string;
  members: IOrganizationCollaborator[];
}

export const OrganizationCollaborator: FC<OrganizationCollaboratorProps> = (props) => {
  const { member } = props;
  const { user, role } = member;
  const { usu_name, usu_email } = user;
  const { role_name, role_description, role_constant } = role;

  const { api } = useThumbzApi();
  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const loggedUser = useSelector(authSlice.selectors.selectUser);
  
  const mutation = useMutation({
    mutationFn: api.organization.organizationControllerUninvite,
    onSuccess() {
      toast.success('Member removed');
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          queryKeys.organization
            .invitations({ org_id: props.org_id })
            .queryKey.find((key) => queryKey.includes(key)) !== undefined,
      });
      setOpen(false);
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  async function handleRemove() {
    await mutation.mutateAsync({ org_id: props.org_id, usu_id: user.usu_id });
  }
  const { loggedUserRole, isTheSameUser, isOwner } = useUserRole(
    props.members,
    loggedUser?.usu_id || null,
    user.usu_id
  );

  return (
    <>
      <Box
        sx={{
          borderColor: 'divider',
          borderRadius: 1,
          borderStyle: 'solid',
          borderWidth: 1,
          px: 3,
          py: 4,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <RenderAvatar
            name={usu_name}
            src={member.user.usu_photo.ast_url}
          ></RenderAvatar>
          <div>
            <Typography variant="subtitle2">{usu_name}</Typography>
            <Typography
              color="text.secondary"
              variant="body2"
            >
              {usu_email}
            </Typography>
          </div>
        </Stack>
        {/* The skills stack remains unchanged */}
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          spacing={1}
          sx={{ mt: 2 }}
        >
          <Tooltip
            title={role_description}
            placement="top"
          >
            <Chip
              key={role_constant}
              label={role_name}
              size="small"
            />
          </Tooltip>
          {!isTheSameUser && isOwner && (
            <LoadingButton
              variant="text"
              color="secondary"
              size="small"
              onClick={() => setOpen(true)}
            >
              Remover
            </LoadingButton>
          )}
        </Stack>
      </Box>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        title="Confirmação de Remoção"
        description="Você tem certeza que irá remover este membro do seu Workspace?"
        confirmText="Remover"
        onConfirm={() => handleRemove()}
        isPending={mutation.isPending}
        onSuccessful={() => {}}
      />
    </>
  );
};

OrganizationCollaborator.propTypes = {
  // @ts-ignore
  member: PropTypes.object.isRequired,
};
