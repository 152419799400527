import React, { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import Clarity from "@microsoft/clarity";
import * as Sentry from "@sentry/react";
import { routeTree } from "./routeTree.gen";
import { createClient } from "./utils/supabase/client";

// Sentry.init({
//   dsn: "https://7447db71738380c7fe0e13236e90a71c@o4507386074562560.ingest.us.sentry.io/4508411172421632",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//     // Sentry.feedbackIntegration({
//     //   colorScheme: "system",
//     //   triggerLabel: "Ajuda",
//     //   triggerAriaLabel: "Relatar um Bug",
//     //   formTitle: "Relatar um Bug",
//     //   submitButtonLabel: "Enviar Relatório de Bug",
//     //   cancelButtonLabel: "Cancelar",
//     //   confirmButtonLabel: "Confirmar",
//     //   addScreenshotButtonLabel: "Adicionar uma captura de tela",
//     //   removeScreenshotButtonLabel: "Remover captura de tela",
//     //   nameLabel: "Nome",
//     //   namePlaceholder: "Seu Nome",
//     //   emailLabel: "E-mail",
//     //   emailPlaceholder: "seu.email@exemplo.com",
//     //   isRequiredLabel: "(obrigatório)",
//     //   messageLabel: "Descrição",
//     //   messagePlaceholder: "Qual é o bug? O que você esperava?",
//     //   successMessageText: "Obrigado pelo seu relatório!",
//     //   autoInject: false,
//     // }),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/api.thumbz.com.br/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   environment: import.meta.env.MODE,
//   release: import.meta.env.APP_VERSION,
//   beforeSend(event, { event_id }) {
//     // Check if it is an exception, and if so, show the report dialog
//     if (event.exception && event_id) {
//       Sentry.showReportDialog({
//         eventId: event.event_id,
//         title: "Ooops! Encontramos um problema.",
//         labelClose: "Fechar",
//         labelSubmit: "Enviar",
//         labelName: "Nome",
//         labelEmail: "E-mail",
//         labelComments: "Comentários",
//         subtitle: "Se precisar de ajuda com isso, informe o código ou descreva o problema.",
//         subtitle2: event_id,
//         successMessage: "Obrigado por enviar o relatório! Vamos investigar o problema.",
//         lang: "pt-BR",
//         user: {
//           email: event.user?.email,
//           name: event.user?.name,
//         },
//       });
//     }
//     return event;
//   },
// });

if (import.meta.env.MODE === "production") {
  if (import.meta.env.VITE_MICROSOFT_CLARITY_PROJECT_ID) {
    Clarity.init(import.meta.env.VITE_MICROSOFT_CLARITY_PROJECT_ID);
  }
}

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <h1>404 Not Found</h1>,
  context: {
    auth: undefined!,
    dispatch: store.dispatch,
    getState: store.getState,
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

import { LinkProps as TanstackLinkProps } from "@tanstack/react-router";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { useAppDispatch, useAppSelector } from "./app/hooks";
export type RouterPaths = TanstackLinkProps["to"];

export function InnerApp() {
  const { auth } = createClient();

  useEffect(() => {
    const {
      data: { subscription },
    } = auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        Sentry.setUser({
          usu_external_uid: session.user.id,
          email: session.user.email,
          username: session.user.user_metadata?.username,
        });
      } else {
        Sentry.setUser(null);
      }

      if (event === "SIGNED_IN" || event === "SIGNED_OUT" || event === "PASSWORD_RECOVERY") {
        router.invalidate();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [auth]);

  return (
    <RouterProvider
      router={router}
      context={{ auth, dispatch: store.dispatch, getState: store.getState }}
    />
  );
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <Provider store={store}>
        <InnerApp />
      </Provider>
    </StrictMode>,
  );
}
